<template>
  <div>
    <div class="mb-8">
      <FormLabel label="種別選択" />
      <Select
        v-model="shiftType"
        :options="shiftTypes"
        :option-label="shiftTypeLabel"
        class="w-100"
      />
    </div>

    <template v-if="shiftType !== 'holiday' && shiftType !== 'paid_holiday'">
      <div class="mb-8">
        <FormLabel label="業務選択" />
        <Select
          v-model="contentType"
          :options="shiftContentTypes"
          :option-label="shiftContentTypeLabel"
          class="w-100"
        />
      </div>

      <div class="mb-8">
        <FormLabel label="業務詳細" />
        <FormItemText
          v-model="contentDetails"
          :errors="errors"
          form-id="contentDetails"
          form-type="text"
          :rows="3"
        />
      </div>

      <div class="mb-8">
        <FormLabel label="就業時間" />
        <div class="flex">
          <FormItemTime
            v-model="startAt"
            :errors="errors"
            form-id="startAt"
            :minutes-increment="10"
            class="me-4"
          />
          <FormItemTime
            v-model="finishAt"
            :errors="errors"
            form-id="finishAt"
            :minutes-increment="10"
            class="ms-4"
          />
        </div>
      </div>

      <div class="mb-8">
        <FormLabel label="休憩時間" />
        <div class="flex">
          <FormItemTime
            v-model="restStartAt"
            :errors="errors"
            form-id="restStartAt"
            :minutes-increment="10"
            class="me-4"
          />
          <FormItemTime
            v-model="restFinishAt"
            :errors="errors"
            form-id="restFinishAt"
            :minutes-increment="10"
            class="ms-4"
          />
        </div>
      </div>
    </template>

    <!-- div class="mb-8">
      <FormLabel label="公開日時 ※未設定の場合は即座に公開されます" />
      <div class="flex">
        <FormItemDate
          v-model="publishedAt"
          :errors="errors"
          form-id="publishedAt"
          form-type="date"
          class="me-2"
          style="width: 210px; min-width: 210px"
        />
        <FormItemTime
          v-model="publishedAt"
          :env="publishedAtEnv"
          :errors="errors"
          form-id="publishedAt"
          label=""
          :minutes-increment="10"
          :style="
            isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: '120px',
                }
          "
        />
      </div>
    </-->
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { fromISO, luxonNow } from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { FormItemText, FormItemTime } from "/@/vue/components/Molecules/Form";
import {
  ZodFormattedErrors,
  ShiftClient,
  shiftTypes,
  shiftTypeLabel,
  shiftContentTypeLabel,
  shiftContentTypes,
} from "/@/types";
import Select from "primevue/select";

const shiftType = defineModel<ShiftClient["shiftType"]>("shiftType", {
  required: true,
});
const startAt = defineModel<ShiftClient["startAt"]>("startAt");
const finishAt = defineModel<ShiftClient["finishAt"]>("finishAt");
const restStartAt = defineModel<ShiftClient["restStartAt"]>("restStartAt");
const restFinishAt = defineModel<ShiftClient["restFinishAt"]>("restFinishAt");
const contentType = defineModel<ShiftClient["contentType"]>("contentType");
const contentDetails =
  defineModel<ShiftClient["contentDetails"]>("contentDetails");

const props = defineProps<{
  errors: ZodFormattedErrors;
}>();

const { isMobile } = useMqUtils();

const now = computed(() => {
  return luxonNow().startOf("day");
});
</script>

<style scoped></style>
