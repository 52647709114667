<template>
  <div>
    <div class="flex justify-between mb-8">
      <SelectObject
        v-if="organizations && organizations.length"
        v-model="selectedOrganizationId"
        option-label="name"
        option-value="id"
        :options="organizations"
      />
      <BasicButton> ラベルの追加・変更 </BasicButton>
    </div>

    <DataTable :value="organizationMembers">
      <Column
        :field="
          (om) =>
            `${om.user.lastName}${om.user.firstName}(${om.user.lastNameKana}${om.user.firstNameKana})`
        "
        header="名前"
        style="width: 200px"
      ></Column>
      <Column header="雇用形態" style="width: 200px">
        <template #body="{ data }">
          <Select
            :model-value="
              data.labels
                .filter((l) => !l._destroy && l.labelKind === 'employee_type')
                .map((l) => l.labelType)[0]
            "
            :options="
              labelOptions.filter((l) => l.labelKind === 'employee_type')
            "
            option-value="labelType"
            :option-label="(l) => organizationMemberLabelTypeLabel(l.labelType)"
            filter
            @update:model-value="updateMemberEmployeeType(data, $event)"
          >
            <!--template #chipicon="{ item }">
              <span
                class="p-chips-token-icon p-cursor-pointer"
                @click="removeMemberLabels(data, item)"
              >
                <i class="pi pi-times-circle"></i>
              </span>
            </ template -->
          </Select>
        </template>
      </Column>
      <Column header="役職">
        <template #body="{ data }">
          <MultiSelect
            :model-value="
              data.labels
                .filter((l) => !l._destroy && l.labelKind === 'role')
                .map((l) => l.labelType)
            "
            :options="labelOptions.filter((l) => l.labelKind === 'role')"
            option-value="labelType"
            :option-label="(l) => organizationMemberLabelTypeLabel(l.labelType)"
            filter
            @update:model-value="updateMemberLabels(data, $event)"
          >
            <!--template #chipicon="{ item }">
              <span
                class="p-chips-token-icon p-cursor-pointer"
                @click="removeMemberLabels(data, item)"
              >
                <i class="pi pi-times-circle"></i>
              </span>
            </ template -->
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  useRouterUtil,
  useOrganizationMember,
  useUser,
} from "/@/vue/composables";
import { SelectObject, BasicButton } from "/@/vue/components/Atom";
import {
  OrganizationMemberClient,
  OrganizationMemberLabelType,
  organizationMemberLabelTypeLabel,
  organizationMemberLabelTypes,
} from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Select from "primevue/select";

const { goto } = useRouterUtil();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

// organization

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const selectedOrganizationId = ref<number>();
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

const isManager = computed(() => selectedOrganization.value?.isManager);

const { getOrganizationMembers, updateOrganizationMember } =
  useOrganizationMember();
const { data: organizationMembers, mutate: getOrganizationMembersMutate } =
  getOrganizationMembers(selectedOrganizationId);

// label

const labelOptions = organizationMemberLabelTypes.map((l) => ({
  labelType: l,
  labelKind: l === "manager" ? "role" : "employee_type",
}));

async function updateMemberEmployeeType(
  om: OrganizationMemberClient,
  event: { labelType: OrganizationMemberLabelType }
) {
  console.log("updateMemberLabels", event);

  om.labels = [
    ...om.labels.filter((l) => l.labelKind !== "employee_type"),
    {
      labelType: event,
    },
  ];

  try {
    if (await updateOrganizationMember({ id: om.id, labels: om.labels })) {
      getOrganizationMembersMutate();
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

async function updateMemberLabels(
  om: OrganizationMemberClient,
  event: [{ labelType: OrganizationMemberLabelType }]
) {
  const labels = om.labels.filter((l) => {
    return event.filter((e) => e.labelKind === "role");
  });

  console.log("updateMemberLabels", labels);
  console.log("updateMemberLabels", event);

  om.labels = [
    ...labels,
    ...event
      .filter((e) => !labels.find((l) => l.labelType === e))
      .map((e) => {
        return { labelType: e };
      }),
  ];

  try {
    if (await updateOrganizationMember({ id: om.id, labels: om.labels })) {
      getOrganizationMembersMutate();
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}

/*
function removeMemberLabels(
  om: OrganizationMemberClient,
  item: {
    labelType: OrganizationMemberLabelType;
    _destroy?: boolean;
  }
) {
  console.log("removeMemberLabel", item);
  const target = om.labels.find((l) => l.labelType === item.labelType);
  target._destroy = 1;
}
*/
</script>

<style scoped></style>
