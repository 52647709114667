import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { Shift, ShiftScheme } from "/@/types/shift.d";
import { UserClientScheme } from "/@/types/user.d";
import { LaborInformationClientScheme } from "/@/types/laborInformation.d";
import { first } from "lodash";
import { PhoneNumber } from "libphonenumber-js";

// label

// 組織管理者 役員 アルバイト 契約社員

export const organizationMemberLabelTypes = [
  "manager",
  "officer",
  "part_time",
  "contract",
];
export const OrganizationMemberLabelTypeScheme = z.enum(
  organizationMemberLabelTypes
);
export type OrganizationMemberLabelType = zod.infer<
  typeof OrganizationMemberLabelTypeScheme
>;

export function organizationMemberLabelTypeLabel(
  labelType: OrganizationMemberLabelType
) {
  switch (labelType) {
    case "manager":
      return "組織管理者";
    case "officer":
      return "役員";
    case "part_time":
      return "アルバイト";
    case "contract":
      return "契約社員";
  }
}

export const OrganizationMemberLabelScheme = z.object({
  id: z.number().optional(),
  labelType: OrganizationMemberLabelTypeScheme,
});

// info

// additional info
export const BusinessAccountScheme = z.object({
  email: z.string().email(),
  password: z.string().min(6),
  serviceName: z.string().trim().min(1).max(50).optional().or(z.literal("")),
  firstName: z.string().trim().min(1).max(50).optional().or(z.literal("")),
  lastName: z.string().trim().min(1).max(50).optional().or(z.literal("")),
  birthday: z.string().trim().min(5).optional().or(z.literal("")),
  phoneNumber: z.string().trim().min(10).max(11).optional().or(z.literal("")),
  createdOn: z.string().optional(), // 実際のデータ追加時と、Log 上での追加が異なる場合があることへの対応
  disusedOn: z.string().optional(),
});

export type BusinessAccount = zod.infer<typeof BusinessAccountScheme>;

export const simTypes = ["sim", "esim"] as const;
export const simCompanyTypes = [
  "docomo",
  "au",
  "softbank",
  "uqmobile",
  "OCN",
  "LIBMO",
  "Y!mobile",
] as const;
export const simContractTypes = ["individual", "business"] as const;
export const SimContractType = z.enum(simContractTypes);
export type SimContractType = zod.infer<typeof SimContractType>;

export function simContractTypeLabel(t: SimContractType) {
  switch (t) {
    case "individual":
      return "個人";
    case "business":
      return "法人";
  }
}

export const BusinessPhoneScheme = z.object({
  startOn: z.string().optional(),
  returnOn: z.string().optional(),
  simStartOn: z.string().optional(),
  leastOn: z.string(), // 最低利用期間
  phoneNumber: z.string().trim().min(10).max(11),
  simType: z.enum(simTypes),
  simCompany: z.enum(simCompanyTypes),
  simContractType: z.enum(simContractTypes),
  simContractDetails: z.string().trim().min(1).max(300),
  deviceType: z.string().trim().min(1).max(50),
  deviceCapacity: z.string().trim().min(1).max(50),
  deviceColor: z.string().trim().min(1).max(50),
  imei: z.string().trim().min(1).max(50),
  remarks: z.string().trim().min(1).max(300),
});

export type BusinessPhone = zod.infer<typeof BusinessPhoneScheme>;

export const bankAccountTypes = ["checking", "savings"] as const;
export const BankAccountTypeScheme = z.enum(bankAccountTypes);

export const OrganizationMemberInfoScheme = z.object({
  bankName: z.string().trim().min(1).max(50).optional(), // 銀行名
  bankBranchName: z.string().trim().min(1).max(50).optional(), // 支店名
  bankAccountType: BankAccountTypeScheme, // 普通 当座
  bankAccountNumber: z.string().trim().min(1).max(50).optional(), // 口座番号
  bankAccountHolder: z.string().trim().min(1).max(50).optional(), // 口座名義
  businessAccounts: BusinessAccountScheme.array().optional(), // 業務用メールアドレス
  // createdAt: z.string().optional(), アカウント作成日
  businessPhones: BusinessPhoneScheme.array().optional(), // 業務用電話番号
});

// member

export const OrganizationMemberScheme = z
  .object({
    id: z.number().optional(),
    organizationId: z.number().optional(),
    userId: z.number().optional(),
    code: z.string(),
    joiningOn: z.string(),
  })
  .merge(OrganizationMemberInfoScheme);

export type OrganizationMember = zod.infer<typeof OrganizationMemberScheme>;

export const OrganizationMemberCheckScheme = OrganizationMemberScheme;

export const OrganizationMemberClientScheme = OrganizationMemberScheme.extend({
  id: z.number(),
  userId: z.number(),
  organizationId: z.number(),
  user: UserClientScheme,
  labels: OrganizationMemberLabelScheme.array(),
  laborInformations: LaborInformationClientScheme.array(),
});
export type OrganizationMemberClient = zod.infer<
  typeof OrganizationMemberClientScheme
>;

export const OrganizationMemberFormScheme = OrganizationMemberScheme.partial({
  id: true,
  code: true,
  joiningOn: true,
}).extend({
  shifts: ShiftScheme.array(),
  labels: OrganizationMemberLabelScheme.array(),
});

export type OrganizationMemberForm = zod.infer<
  typeof OrganizationMemberFormScheme
>;
