export function scrollToTop(targetEl?: HTMLElement) {
  if (targetEl) {
    targetEl.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return;
  } else {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}

export function scrollToBottom() {
  // sleep 0.5s
  setTimeout(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, 250);
}

export function scrollToId(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}
