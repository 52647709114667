<template>
  <div>
    <Datepicker
      :model-value="jsDate"
      :manual-input="false"
      date-format="hh:mm"
      :invalid="invalid"
      :step-minute="minutesIncrement"
      time-only
      show-icon
      :disabled="disabled"
      icon="pi pi-clock"
      @update:model-value="update"
    />
    <!--
    <BasicDatepicker
      :model-value="timeObjValue"
      locale="ja"
      :format="format"
      close-on-scroll
      :preview-format="() => ''"
      select-text="決定"
      cancel-text="キャンセル"
      :time-picker="true"
      :minutes-increment="minutesIncrement"
      :minutes-grid-increment="minutesIncrement"
      :min-time="timeObjMinTime"
      :max-time="timeObjMaxTime"
      :start-time="startTime"
      :state="isValid ? undefined : false"
      :month-change-on-scroll="false"
      :input-class-name="`timepicker ${isPc ? '' : 'mobile-ui'}`"
      :teleport-center="teleportCenter"
      @update:model-value="updateTime"
      @cleared="$emit('cleared')"
    >
      <template #input-icon>
        <div class="d-flex items-center justify-center form-icon-wrapper">
          <i class="pi pi-clock"></i>
        </div>
      </template>
    </BasicDatepicker>
    -->
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  atToTimeObj,
  timeObjToAt,
  createStartTime,
} from "/@/modules/datepicker";
import { LuxonTimeObject, fromISO } from "/@/modules/luxon";
import { zeroPadding } from "/@/modules/string";
import Datepicker from "primevue/datepicker";

const modelValue = defineModel<string | undefined>();

type Props = {
  minTime?: string;
  maxTime?: string;
  startTime?: { hour: number; minute: number };
  env?: LuxonTimeObject;
  minutesIncrement?: number;
  invalid?: boolean;
  isValid?: boolean;
  teleportCenter?: boolean;
  disabled?: boolean;
};

withDefaults(defineProps<Props>(), {
  minTime: undefined,
  maxTime: undefined,
  isPc: false,
  // @ts-ignore
  startTime: { hour: 10, minute: 0 },
  minutesIncrement: 10,
  env: () => ({ year: 2000, month: 1, day: 1 }),
});

const jsDate = computed<Date | Date[] | undefined>(() => {
  if (!modelValue.value) {
    return undefined;
  }

  return fromISO(modelValue.value)?.toJSDate() || undefined;
});

const emit = defineEmits(["cleared"]);

function update(date: Date) {
  modelValue.value = date.toISOString();
}

function format(day: Date) {
  if (!day) {
    return null;
  }

  return `${zeroPadding(day.getHours(), 2)}:${zeroPadding(
    day.getMinutes(),
    2
  )}`;
}
</script>

<style lang="scss" scoped></style>
