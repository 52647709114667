import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { ShiftClientScheme } from "./shift.d";
import { AttendanceClientScheme } from "./attendance.d";

export const HomeInfoScheme = z.object({
  shifts: ShiftClientScheme.array(),
  currentAttendance: AttendanceClientScheme.optional(),
  todayFinishedAttendances: AttendanceClientScheme.array(),
  attendances: AttendanceClientScheme.array(),
});

export type HomeInfo = zod.infer<typeof HomeInfoScheme>;
