import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { BrowserPhotoScheme } from "/@/types/browserPhoto.d";

export const attendanceType = ["working", "holiday", "paid_holiday"] as const;
export const AttendanceTypeScheme = z.enum(attendanceType);
export type AttendanceType = zod.infer<typeof AttendanceTypeScheme>;

export const evaluationTypes = ["excellent", "good", "bad"] as const;
export const EvaluationTypeScheme = z.enum(evaluationTypes);
export type EvaluationType = zod.infer<typeof EvaluationTypeScheme>;

export function evaluationTypeLabel(type: EvaluationType): string {
  switch (type) {
    case "bad":
      return "😢 悪い";
    case "good":
      return "😊 良い";
    case "excellent":
      return "🎉 最高";
  }
}

export const AttendanceScheme = z.object({
  id: z.number().optional(),
  organizationMemberId: z.number().optional(),
  attendanceType: AttendanceTypeScheme,
  startAt: z.string().optional(),
  finishAt: z.string().optional(),
  evaluationType: EvaluationTypeScheme.optional(),
  report: z.string().optional(),
  bodyTemperature: z.number().optional(),
  correctionStartAt: z.string().optional(),
  correctionFinishAt: z.string().optional(),
  startLat: z.number().optional(),
  startLng: z.number().optional(),
  finishLat: z.number().optional(),
  finishLng: z.number().optional(),
});
export type Attendance = zod.infer<typeof AttendanceScheme>;

export const AttendanceCheckScheme = AttendanceScheme;

export const AttendanceFormScheme = AttendanceScheme;
export type AttendanceForm = zod.infer<typeof AttendanceFormScheme>;

export const AttendanceClientScheme = AttendanceScheme.extend({
  id: z.number(),
  startAt: z.string(),
  finishAt: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
});
export type AttendanceClient = zod.infer<typeof AttendanceClientScheme>;

// absence

// 体調不良、家族の体調不良, 家庭の都合、身内の不孝、シフトが変更になった(欠勤扱いしない)
export const absenceTypes = [
  "illness",
  "family_illness",
  "family_affairs",
  "bereavement",
  "shift_change",
] as const;
export const AbsenceTypeScheme = z.enum(absenceTypes);
export type AbsenceType = zod.infer<typeof AbsenceTypeScheme>;

export function absenceTypesLabel(type: AbsenceType): string {
  switch (type) {
    case "illness":
      return "体調不良";
    case "family_illness":
      return "家族の体調不良";
    case "family_affairs":
      return "家庭の都合";
    case "bereavement":
      return "身内の不孝";
    case "shift_change":
      return "シフトが変更になった";
  }
}

export const AbsenceScheme = z.object({
  absenceType: AbsenceTypeScheme,
  bodyTemperature: z.number().max(44).min(34).optional(),
  photo: BrowserPhotoScheme.optional().optional(),
  explanation: z.string().optional(),
});

export const AbsenceCheckScheme = AbsenceScheme;

export const AbsenceFormScheme = AbsenceScheme;
export type AbsenceForm = zod.infer<typeof AbsenceFormScheme>;

export const AbsenceClientScheme = AbsenceScheme.extend({
  createdAt: z.string(),
  updatedAt: z.string(),
});
export type AbsenceClient = zod.infer<typeof AbsenceClientScheme>;
