<template>
  <div class="flex items-center justify-end w-full">
    <span class="me-2">表示件数</span>
    <Select
      v-model="selectedPerPage"
      :options="options"
      option-label="label"
      option-value="value"
      :allow-empty="false"
      class="me-2"
    />
    <span>{{ currentPage }}/{{ totalPages }}ページ</span>
  </div>
</template>

<script setup lang="ts">
import Select from "primevue/select";
import { computed } from "vue";

const props = defineProps<{
  totalPages: number;
  currentPage: number;
  perPageOptions?: number[];
}>();

const selectedPerPage = defineModel<number>({
  default: 10,
});

const options = computed(() => {
  return (props.perPageOptions || [10, 30, 50]).map((n) => ({
    label: n.toString(),
    value: n,
  }));
});
</script>

<style scoped></style>
