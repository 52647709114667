<template>
  <div>
    <div class="mb-8">
      <FormLabel label="名前 ※50文字以内" />
      <FormItemText
        v-model="name"
        :errors="errors"
        form-id="name"
        form-type="text"
      />
    </div>

    <FormItemText
      v-model="phoneNumber"
      :errors="errors"
      label="電話番号(必須)"
      form-id="phoneNumber"
      form-type="text"
      class="mb-8 w-100"
      style="max-width: 300px"
    />

    <div class="mb-8 w-full">
      <FormLabel label="郵便番号(必須) ※数字7桁" />
      <FormItemText
        v-model="postalCode"
        :errors="errors"
        form-id="postalCode"
        form-type="text"
        autocomplete="postal-code"
        style="max-width: 250px"
      />
    </div>

    <div class="flex items-center mb-8">
      <div class="me-2">
        <FormLabel label="都道府県(選択)" />
        <Select
          v-model="addressLevel1"
          :options="prefectures"
          option-label="label"
          option-value="value"
          :errors="errors"
          filter
          no-empty-label="都道府県を選択してください。"
          label="都道府県"
          form-id="area"
          :invalid="!!errors.addressLevel1"
        >
        </Select>
      </div>

      <div class="ms-2">
        <FormLabel label="市区町村(必須) " />
        <FormItemText
          v-model="addressLevel2"
          :errors="errors"
          form-id="addressLevel2"
          form-type="text"
          autocomplete="address-level2"
        />
      </div>
    </div>

    <div class="mb-8">
      <FormLabel label="上記以降(必須)" />
      <FormItemText
        v-model="addressLine1"
        :errors="errors"
        form-id="addressLevel2"
        form-type="text"
        autocomplete="address-line1"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { prefectures } from "/@/modules/address";
import { Label as FormLabel } from "/@/vue/components/Atom";
import { FormItemText } from "/@/vue/components/Molecules/Form";
import { ZodFormattedErrors } from "/@/types";
import Select from "primevue/select";

const name = defineModel<string | undefined>("name");
const phoneNumber = defineModel<string | undefined>("phoneNumber");
const postalCode = defineModel<string | undefined>("postalCode");
const addressLevel1 = defineModel<string | undefined>("addressLevel1");
const addressLevel2 = defineModel<string | undefined>("addressLevel2");
const addressLine1 = defineModel<string | undefined>("addressLine1");
const addressLine2 = defineModel<string | undefined>("addressLine2");

defineProps<{
  errors: ZodFormattedErrors;
}>();
</script>

<style scoped></style>
