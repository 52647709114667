import { Ref } from "vue";
import useSWRV from "swrv";
import { axios, deepUndefineKeyToNull, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { ShiftFormType } from "/@/types";

export function useShift() {
  async function updateShift(id: number, form: ShiftFormType) {
    try {
      const { data } = await axios.patch(`/api/v1/shifts/${id}`, {
        shift: deepUndefineKeyToNull(form),
      });
      return data;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function destroyShift(id: number) {
    try {
      await axios.delete(`/api/v1/shifts/${id}`);
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  return {
    updateShift,
    destroyShift,
  };
}
