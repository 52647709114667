<template>
  <div>
    <ArticleForm
      v-model:label="form.label"
      v-model:title="form.title"
      v-model:published-at="form.publishedAt"
      v-model:content="form.content"
      v-model:new-attached-files="form.newAttachedFiles"
      :attached-files="[]"
      :errors="errors"
    />

    <div class="debug max-w-full">form: {{ form }}</div>

    <div class="flex justify-end w-full">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton button-type="submit" label="作成" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch, computed } from "vue";
import { useZodScheme, useRouterUtil, useArticle } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as ArticleForm } from "/@/vue/components/Organisms/Articles";
import { ArticleCheckScheme, ArticleForm as ArticleFormType } from "/@/types";

const { goto, backto, currentRouteQuery } = useRouterUtil();

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<ArticleFormType>(
  ArticleCheckScheme,
  {
    label: "announce",
    newAttachedFiles: [],
  }
);

watch(form, (f) => {
  if (f.title && f.title.length > 50) {
    form.title = f.title.slice(0, 50);
  }
});

onMounted(() => {
  startValidation.value = true;
});

const { createArticle } = useArticle();

async function submit() {
  try {
    startValidation.value = true;

    const args = ArticleCheckScheme.parse(form);

    if (await createArticle(args)) {
      alert("作成に成功しました");
      goto({ name: "UsersArticlesIndex" });
    } else {
      alert(
        "作成に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい");
    console.error(e);
  }
}
</script>

<style scoped></style>
