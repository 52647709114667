<template>
  <div>
    <div class="flex items-center justify-between mb-8">
      <SelectObject
        v-if="organizations && organizations.length"
        v-model="selectedOrganizationId"
        option-label="name"
        option-value="id"
        :options="organizations"
        class="me-2"
      />

      <div v-if="isManager">
        <BasicButton
          class="me-4"
          @click="goto({ name: 'UsersOrganizationMemberLabelsIndex' })"
        >
          ラベル管理画面
        </BasicButton>

        <BasicButton @click="goto({ name: 'UsersNew' })">
          ユーザーの追加
        </BasicButton>
      </div>
    </div>

    <Card v-if="selectedOrganization" class="mb-8">
      <template #content>
        <Accordion value="0">
          <AccordionPanel value="0" class="!border-0">
            <AccordionHeader>
              <div class="flex justify-between items-center w-full">
                <span>組織情報</span>
                <BasicButton
                  v-if="selectedOrganization.isManager"
                  class="me-4"
                  @click="
                    goto({
                      name: 'UsersOrganizationsEdit',
                      params: { id: selectedOrganization.id },
                    })
                  "
                >
                  編集
                </BasicButton>
              </div>
            </AccordionHeader>
            <AccordionContent>
              <ShowFormItemWrapper title="組織名">
                {{ selectedOrganization.name }}
              </ShowFormItemWrapper>
              <ShowFormItemTel
                title="電話番号"
                :tel="selectedOrganization.phoneNumber"
              />
              <ShowFormItemAddress
                title="住所"
                v-bind="selectedOrganization"
                without-divider
              />
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

    <Card
      v-if="isManager && organizationMembers && organizationMembers.length > 1"
      class="mb-8"
    >
      <template #content>
        <Accordion value="0">
          <AccordionPanel value="0" class="!border-0">
            <AccordionHeader>
              <div class="flex items-center">
                <span class="me-4">メンバー情報</span>
                <BasicButton @click="showBankInfoProc"
                  >銀行情報の表示</BasicButton
                >
              </div>
            </AccordionHeader>
            <AccordionContent>
              <DataTable
                :value="organizationMembers"
                striped-rows
                scrollable
                selection-mode="single"
                size="small"
                @update:selection="memberSelect"
              >
                <Column header="認証" style="width: 50px">
                  <template #body="{ data }">
                    <span v-if="!data.user.confirmed">
                      <i class="pi pi-exclamation-triangle text-red-500"></i>
                    </span>
                  </template>
                </Column>
                <Column
                  :field="
                    (om) =>
                      `${om.user.lastName}${om.user.firstName}(${om.user.lastNameKana}${om.user.firstNameKana})`
                  "
                  header="名前"
                  style="width: 200px"
                ></Column>
                <Column
                  :field="(om) => om.user.email"
                  header="メールアドレス"
                  style="width: 300px"
                >
                </Column>
                <Column
                  :field="(om) => om.user.initPassword"
                  header="初期パスワード"
                  style="width: 150px"
                >
                </Column>
                <Column field="code" header="社員番号" style="width: 100px" />
                <Column field="joiningOn" header="入社日" style="width: 200px">
                  <template #body="{ data }">
                    {{ basicFormatter(data.joiningOn) }}
                  </template>
                </Column>
                <Column
                  v-if="showBankInfo"
                  field="bankName"
                  header="銀行名"
                  style="width: 100px"
                />
                <Column
                  v-if="showBankInfo"
                  field="bankBranchName"
                  header="支店名"
                  style="width: 100px"
                />
                <Column
                  v-if="showBankInfo"
                  field="bankAccountType"
                  header="口座種別"
                  style="width: 100px"
                >
                  <template #body="{ data }">
                    {{ bankAccountTypeLabel(data.bankAccountType) }}
                  </template>
                </Column>
                <Column
                  v-if="showBankInfo"
                  field="bankAccountNumber"
                  header="口座番号"
                  style="width: 100px"
                />

                <Column
                  header="ラベル"
                  :field="
                    (om) =>
                      om.labels
                        .map((l) =>
                          organizationMemberLabelTypeLabel(l.labelType)
                        )
                        .join()
                  "
                  style="width: 200px"
                >
                </Column>
              </DataTable>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

    <SimpleModal v-model:visible="memberConfigModal" title="メンバー情報の編集">
      <template v-if="selectedMember">
        <div class="mb-8">
          {{ selectedMember.user.lastName }}{{ selectedMember.user.firstName }}
        </div>
        <OrganizationMemberForm
          v-model:code="form.code"
          v-model:joiningOn="form.joiningOn"
          v-model:bankName="form.bankName"
          v-model:bankBranchName="form.bankBranchName"
          v-model:bankAccountType="form.bankAccountType"
          v-model:bankAccountNumber="form.bankAccountNumber"
          v-model:bankAccountHolder="form.bankAccountHolder"
          v-model:businessAccounts="form.businessAccounts"
          v-model:businessPhones="form.businessPhones"
          :errors="errors"
          :startValidation="startValidation"
        />

        <div class="flex justify-end">
          <BasicButton @click="submit">更新</BasicButton>
        </div>
      </template>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  useRouterUtil,
  useUser,
  useOrganizationMember,
  useZodScheme,
} from "/@/vue/composables";
import {
  ShowFormItemWrapper,
  ShowFormItemTel,
  ShowFormItemAddress,
} from "/@/vue/components/Molecules";
import { SelectObject, BasicButton } from "/@/vue/components/Atom";
import { Form as OrganizationMemberForm } from "/@/vue/components/Organisms/OrganizationMembers";
import Card from "primevue/card";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import {
  bankAccountTypeLabel,
  OrganizationMemberCheckScheme,
  OrganizationMemberClient,
  OrganizationMemberForm as OrganizationMemberFormType,
  organizationMemberLabelTypeLabel,
} from "/@/types";
import { basicFormatter } from "/@/modules/luxon";

const { goto } = useRouterUtil();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

// organization

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const selectedOrganizationId = ref<number>();
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

const isManager = computed(() => selectedOrganization.value?.isManager);

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganizationId
);

const selectedOrganizationMemberId = ref<number>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value) return;
  return organizationMembers.value.find(
    (m) => m.id === selectedOrganizationMemberId.value
  );
});

// table

const showBankInfo = ref(false);

function showBankInfoProc(e: any) {
  showBankInfo.value = !showBankInfo.value;
}

// member select
const memberConfigModal = ref(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationMemberFormType>(OrganizationMemberCheckScheme);

function resetForm(om: OrganizationMemberClient) {
  form.id = om.id;
  form.code = om.code;
  form.joiningOn = om.joiningOn;
  form.bankName = om.bankName;
  form.bankBranchName = om.bankBranchName;
  form.bankAccountType = om.bankAccountType;
  form.bankAccountNumber = om.bankAccountNumber;
  form.bankAccountHolder = om.bankAccountHolder;
  form.businessAccounts = om.businessAccounts;
  form.businessPhones = om.businessPhones;
}

const selectedMember = ref<OrganizationMemberClient>();

function memberSelect(e: OrganizationMemberClient) {
  selectedMember.value = e;
  memberConfigModal.value = true;
  resetForm(e);
}

const { updateOrganizationMember } = useOrganizationMember();

async function submit() {
  try {
    startValidation.value = true;

    if (!selectedMember.value) {
      alert("メンバーが選択されていません。");
      return;
    }

    const args = OrganizationMemberCheckScheme.parse(form);

    if (await updateOrganizationMember(args)) {
      alert("更新しました");
      goto({ name: "UsersOrganizationsIndex" });
    } else {
      alert("更新に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
