import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const BrowserPhotoScheme = z.object({ data: z.string() });
export type BrowserPhoto = zod.infer<typeof BrowserPhotoScheme>;

export const PhotoDataObjectScheme = z.object({
  data: z.string(),
});

export type PhotoDataObject = zod.infer<typeof PhotoDataObjectScheme>;
