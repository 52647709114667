import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "UsersPlansIndex",
    component: Index,
    meta: {
      title: "業務計画",
    },
  },
];
