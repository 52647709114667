import { Ref } from "vue";
import useSWRV from "swrv";
import { axios, deepUndefineKeyToNull, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { Article, ArticleClient, ArticleLabel } from "/@/types";

export function useArticle() {
  function getArticle(
    id: Ref<number>,
    options: { admin: boolean } = { admin: false }
  ) {
    if (options.admin) {
      return useSWRV<ArticleClient>(
        () => (id.value && `/api/v1/articles/${id.value}?admin=true`) || null,
        fetcher
      );
    } else {
      return useSWRV<ArticleClient>(
        () => (id.value && `/api/v1/articles/${id.value}`) || null,
        fetcher
      );
    }
  }

  function getArticlesKey(page: Ref<number>, perPage: Ref<number>) {
    let key = "/api/v1/articles?";

    if (page) {
      key += `page=${page.value}`;
    }

    if (perPage) {
      key += `&per_page=${perPage.value}`;
    }

    return key;
  }

  function getArticles(page: Ref<number>, perPage: Ref<number>) {
    return useSWRV<{
      items: ArticleClient[];
      totalPages: number;
      currentPage: number;
    }>(() => getArticlesKey(page, perPage), fetcher);
  }

  function getTrashedArticlesKey(page: Ref<number>, perPage: Ref<number>) {
    let key = "/api/v1/articles/trashed_index?";

    if (page) {
      key += `page=${page.value}`;
    }

    if (perPage) {
      key += `&per_page=${perPage.value}`;
    }

    return key;
  }

  function getTrashedArticles(page: Ref<number>, perPage: Ref<number>) {
    return useSWRV<{
      items: ArticleClient[];
      totalPages: number;
      currentPage: number;
    }>(() => getTrashedArticlesKey(page, perPage), fetcher);
  }

  async function createArticle(article: Article) {
    try {
      await axios.post("/api/v1/articles", {
        article,
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function updateArticle(id: number, article: Article) {
    try {
      await axios.patch(`/api/v1/articles/${id}`, {
        article: deepUndefineKeyToNull(article),
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function destroyArticle(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.patch("/api/v1/articles/bulk_destroy", {
          article: {
            ids: id,
          },
        });
        return true;
      } else {
        await axios.delete(`/api/v1/articles/${id}`);
      }
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function trashArticle(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.patch("/api/v1/articles/bulk_trash", {
          article: {
            ids: id,
          },
        });
      } else {
        await axios.delete(`/api/v1/articles/${id}/trash`);
      }
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function restoreArticle(id: number | number[]) {
    try {
      if (Array.isArray(id)) {
        await axios.patch("/api/v1/articles/bulk_restore", {
          article: {
            ids: id,
          },
        });
        return true;
      } else {
        await axios.patch(`/api/v1/articles/${id}/restore`);
      }

      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  return {
    getArticle,
    getArticles,
    getTrashedArticles,
    createArticle,
    updateArticle,
    destroyArticle,
    trashArticle,
    restoreArticle,
  };
}
