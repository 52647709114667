<template>
  <div v-if="disabled">
    <span> 位置情報は取得していません </span>
  </div>
  <div v-else>
    <span>業務開始のための位置情報取得中です……</span>
    <div
      :class="{
        'text-red-500': locationStatus.status === 'error',
        'text-green-500': locationStatus.status === 'sync-location',
      }"
    >
      <span v-for="m in locationStatus.messages">{{ m }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";

type MessageStatus = "sync-location" | "error";

const lat = defineModel<number | null>("lat");
const lng = defineModel<number | null>("lng");

const props = defineProps<{
  disabled?: boolean;
}>();

const geoWatcher = ref();
const failGetLocation = ref(false);

const locationStatus = reactive<{
  status: MessageStatus;
  messages: string[];
}>({
  status: "sync-location",
  messages: ["読み込み中..."],
});

function success(pos: GeolocationPosition) {
  failGetLocation.value = false;

  locationStatus.status = "sync-location";
  locationStatus.messages = ["取得に成功しました"];

  if (!props.disabled) {
    lat.value = pos.coords.latitude;
    lng.value = pos.coords.longitude;
  } else {
    lat.value = null;
    lng.value = null;
  }
}

function error(err: { code: number; message: string }) {
  console.warn("ERROR(" + err.code + "): " + err.message);
  failGetLocation.value = true;
  locationStatus.status = "error";

  switch (err.code) {
    case 1:
      locationStatus.messages = [
        "位置情報の取得が許可されていません。",
        "ブラウザでの位置情報取得をオンにしてリロードして下さい。",
      ];
      break;

    case 3:
      locationStatus.messages = [
        "位置情報の取得に時間が掛かっており、",
        "電波が悪い・ページの表示に時間がかかっているなどの可能性があります。",
        "解決しない場合はページのリロードを行って下さい。",
      ];
      break;

    default:
      locationStatus.messages = [
        "予期せぬエラーが発生しました。",
        "ページをリロードして下さい。",
      ];
      break;
  }
}

function watchLocation() {
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 5000,
  };

  geoWatcher.value = window.navigator.geolocation.watchPosition(
    success,
    error,
    options
  );
}

watch(
  () => props.disabled,
  (disabled) => {
    if (disabled) {
      lat.value = null;
      lng.value = null;
    }
  }
);

// mounted

onMounted(() => {
  watchLocation();
});

onUnmounted(() => {
  navigator.geolocation.clearWatch(geoWatcher.value);
});
</script>
