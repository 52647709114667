import Edit from "./Edit.vue";
import Index from "./Index.vue";
import New from "./New.vue";
import Show from "./Show.vue";
import Trash from "./Trash.vue";

export default [
  {
    path: "",
    name: "UsersArticlesIndex",
    component: Index,
    meta: {
      title: "書類一覧",
      tree: [],
    },
  },
  {
    path: "edit/:id",
    name: "UsersArticlesEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [{ title: "書類一覧", name: "UsersArticlesIndex" }],
    },
  },
  {
    path: "new",
    name: "UsersArticlesNew",
    component: New,
    meta: {
      title: "新規作成",
      tree: [{ title: "書類一覧", name: "UsersArticlesIndex" }],
    },
  },
  {
    path: "show/:id",
    name: "UsersArticlesShow",
    component: Show,
    meta: {
      title: "詳細",
      tree: [{ title: "書類一覧", name: "UsersArticlesIndex" }],
    },
  },
  {
    path: "trash",
    name: "UsersArticlesTrash",
    component: Trash,
    meta: {
      title: "ゴミ箱",
      tree: [{ title: "書類一覧", name: "UsersArticlesIndex" }],
    },
  },
];
