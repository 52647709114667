<template>
  <div>
    <div class="flex mb-8">
      <FormItemText
        v-model="lastName"
        :errors="errors"
        label="姓(必須)"
        form-id="lastName"
        form-type="text"
        class="me-1"
        style="max-width: 300px"
        :disabled="disabled"
      />
      <FormItemText
        v-model="firstName"
        :errors="errors"
        label="名(必須)"
        form-id="firstName"
        form-type="text"
        class="ms-1"
        style="max-width: 300px"
        :disabled="disabled"
      />
    </div>

    <div class="flex mb-8">
      <FormItemText
        v-model="lastNameKana"
        :errors="errors"
        label="せい(必須)"
        form-id="lastNameKana"
        form-type="text"
        class="me-1"
        style="max-width: 300px"
        :disabled="disabled"
      />
      <FormItemText
        v-model="firstNameKana"
        :errors="errors"
        label="めい(必須)"
        form-id="firstNameKana"
        form-type="text"
        class="ms-1"
        style="max-width: 300px"
        :disabled="disabled"
      />
    </div>

    <div class="mb-8">
      <FormLabel label="生年月日(必須)" />
      <FormItemDate
        v-model="birthday"
        :errors="errors"
        form-id="birthday"
        form-type="date"
        :start-date="fromISO('1990-01-01')"
        style="max-width: 210px"
        :disabled="disabled"
      />
    </div>

    <FormItemEmail
      v-model="email"
      :errors="errors"
      label="メール(必須)"
      form-id="email"
      class="mb-8"
      style="max-width: 300px"
      :disabled="disabled"
    />

    <FormItemEmail
      v-if="!hideEmailCheck"
      v-model="emailCheck"
      :errors="emailCheckErrors"
      label="メール(確認)"
      form-id="emailCheck"
      class="mb-8"
      style="max-width: 300px"
      :disabled="disabled"
    />

    <FormItemText
      v-model="phoneNumber"
      :errors="errors"
      label="携帯電話番号(必須)"
      form-id="phoneNumber"
      form-type="text"
      class="mb-8"
      style="max-width: 300px"
      :disabled="disabled"
    />

    <div class="mb-8 w-full">
      <FormLabel label="郵便番号(必須) ※数字7桁" />
      <FormItemText
        v-model="postalCode"
        :errors="errors"
        form-id="postalCode"
        form-type="text"
        autocomplete="postal-code"
        style="max-width: 250px"
      />
    </div>

    <div class="flex items-center mb-8">
      <div class="me-2">
        <FormLabel label="都道府県(選択)" />
        <Select
          v-model="addressLevel1"
          :options="prefectures"
          option-label="label"
          option-value="value"
          :errors="errors"
          filter
          no-empty-label="都道府県を選択してください。"
          label="都道府県"
          form-id="area"
          :invalid="!!errors.addressLevel1"
        >
        </Select>
      </div>

      <div class="ms-2">
        <FormLabel label="市区町村(必須) " />
        <FormItemText
          v-model="addressLevel2"
          :errors="errors"
          form-id="addressLevel2"
          form-type="text"
          autocomplete="address-level2"
        />
      </div>
    </div>

    <div class="mb-8">
      <FormLabel label="上記以降(必須)" />
      <FormItemText
        v-model="addressLine1"
        :errors="errors"
        form-id="addressLevel2"
        form-type="text"
        autocomplete="address-line1"
      />
    </div>

    <!-- div
      v-if="!props.hideRegistrationNumber && !props.special"
      class="d-flex flex-wrap items-center mb-8"
    >
      <div v-if="false" class="me-2">
        <FormLabel label="職種(選択)" />
        <Dropdown
          :model-value="business"
          :options="businesses"
          :errors="errors"
          placeholder="(未選択)"
          show-clear
          no-empty-label="職種を選択してください。"
          form-id="business"
          :class="{
            'p-invalid': errors.business,
          }"
          @update:model-value="updateBusiness"
        />
      </div>
      <FormItem
        :value="registrationNumber"
        :errors="errors"
        label="登録番号(必須)"
        form-id="registrationNumber"
        form-type="text"
        style="min-width: 200px; max-width: 300px"
        @update:value="
          $emit('update:registrationNumber', $event as string | undefined)
        "
      />
    </-->
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { fromISO } from "/@/modules/luxon";
import { prefectures } from "/@/modules/address";
import { Label as FormLabel } from "/@/vue/components/Atom";
import {
  FormItemText,
  FormItemEmail,
  FormItemDate,
} from "/@/vue/components/Molecules";
import { ZodFormattedErrors } from "/@/types";
import Select from "primevue/select";

const lastName = defineModel<string | undefined>("lastName");
const firstName = defineModel<string | undefined>("firstName");
const lastNameKana = defineModel<string | undefined>("lastNameKana");
const firstNameKana = defineModel<string | undefined>("firstNameKana");
const birthday = defineModel<string | undefined>("birthday");
const phoneNumber = defineModel<string | undefined>("phoneNumber");
const email = defineModel<string | undefined>("email");
const emailCheck = defineModel<string | undefined>("emailCheck");
const postalCode = defineModel<string | undefined>("postalCode");
const addressLevel1 = defineModel<string | undefined>("addressLevel1");
const addressLevel2 = defineModel<string | undefined>("addressLevel2");
const addressLine1 = defineModel<string | undefined>("addressLine1");
const addressLine2 = defineModel<string | undefined>("addressLine2");

defineProps<{
  errors: ZodFormattedErrors;
  disabled?: boolean;
  hideEmailCheck?: boolean;
}>();

const emailCheckErrors = computed(() => {
  if (
    !emailCheck.value ||
    (email.value && emailCheck.value && email.value !== emailCheck.value)
  ) {
    return {
      emailCheck: {
        _errors: ["メールアドレスが一致しません。"],
      },
    };
  }
  return {};
});
</script>

<style scoped></style>
