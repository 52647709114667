<template>
  <div>
    <Dialog
      :visible="visible"
      :header="title"
      modal
      dismissable-mask
      block-scroll
      style="width: 90%; max-width: 800px"
      @after-hide="$emit('afterHide')"
      @update:visible="visible = $event"
    >
      <slot></slot>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";

const visible = defineModel<boolean | undefined>("visible");

const props = defineProps<{
  title: string;
  loading?: boolean;
}>();

defineEmits<{
  (e: "cancel", id: number): void;
  (e: "afterHide"): void;
}>();
</script>

<style scoped></style>
