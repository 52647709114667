<template>
  <div id="user" class="h-full">
    <UserHeader
      ref="userHeader"
      v-if="!isIndexPages"
      :user="user"
      class="-mx-8 -mt-8 sticky top-0 left-0 border-b"
      style="border-color: var(--p-divider-border-color)"
    />
    <router-view
      id="user-root"
      class="py-8"
      :style="{
        'min-height': !isIndexPages ? `calc(100% - ${headerHeight})` : '100%',
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouterUtil, useUser } from "/@/vue/composables";
import { UserHeader } from "/@/vue/components/Layouts";

const { getCurrentUser } = useUser();
const { data: user } = getCurrentUser();
const { currentRouteName, currentRouteNeedLogin } = useRouterUtil();

const isIndexPages = computed(() => {
  if (!currentRouteName.value) return false;

  return !currentRouteNeedLogin.value;
});

const userHeader = ref();

const headerHeight = computed(() => {
  if (!userHeader.value) return "58px";
  return userHeader.value.$el.clientHeight + "px";
});
</script>

<style lang="scss" scoped></style>
