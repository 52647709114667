import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const WorkLogScheme = z.object({
  id: z.number().optional(),
  targetDate: z.string().optional(),
  startAt: z.string().optional(),
  finishAt: z.string().optional(),
});

export type WorkLog = zod.infer<typeof WorkLogScheme>;

export const WorkLogCheckScheme = z.object({
  targetDate: z.string().nonempty(),
  startAt: z.string().nonempty(),
  finishAt: z.string().nonempty(),
});

export const WorkLogClientScheme = WorkLogScheme;

export type WorkLogClient = zod.infer<typeof WorkLogClientScheme>;
