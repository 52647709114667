<template>
  <div>
    <Select
      v-model="modelValue"
      :option-label="optionLabel"
      option-value="id"
      :options="options"
      style="min-width: 150px"
    />
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, onMounted } from "vue";
import Select from "primevue/select";

const modelValue = defineModel<number>();

const props = defineProps<{
  options: any[];
  optionLabel: ((o: any) => string) | string;
  disableAutoSelect?: boolean;
}>();

const selected = computed(() => {
  return props.options.find((o) => o.id === modelValue.value);
});

onMounted(() => {
  if (!modelValue.value && !props.disableAutoSelect) {
    modelValue.value = props.options[0].id;
  }
});
</script>

<style lang="scss" scoped></style>
