import { ArticleLabel } from "/@/types/article.d";

export function labelTranslater(label: ArticleLabel) {
  switch (label) {
    case "announce":
      return "お知らせ";
    case "open_lecture":
      return "公開講座";
    case "training":
      return "研修会";
  }
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("labelTranslater", () => {
    expect(labelTranslater("announce")).toBe("お知らせ");
    expect(labelTranslater("open_lecture")).toBe("公開講座");
    expect(labelTranslater("training")).toBe("研修会");
  });
}
