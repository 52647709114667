<template>
  <div>
    <FormItemWrapper :form-id="formId" :errors="errors" :label="label">
      <PasswordForm
        v-model="modelValue"
        :is-valid="!errors[formId]"
        :form-id="formId"
        :form-type="formType"
        :autocomplete="autocomplete"
        @cleared="modelValue = undefined"
      />
    </FormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { PasswordForm } from "/@/vue/components/Atom";
import { FormItemWrapper } from "/@/vue/components/Molecules";
import { ZodFormattedErrors } from "/@/types";

defineProps<{
  formId: string;
  formType?: string;
  label?: string;
  autocomplete?: "new-password" | "current-password";
  teleportCenter?: boolean;
  errors: ZodFormattedErrors;
}>();

const modelValue = defineModel<string | undefined>();
</script>

<style scoped></style>
