<template>
  <div>
    <div class="mb-8">
      <FormLabel label="社員コード ※50文字以内" />
      <FormItemText
        v-model="code"
        :errors="errors"
        form-id="code"
        form-type="text"
      />
    </div>

    <div class="mb-8">
      <FormLabel label="銀行名 ※50文字以内" />
      <FormItemText
        v-model="bankName"
        :errors="errors"
        form-id="bankName"
        form-type="text"
      />
    </div>

    <div class="mb-8">
      <FormLabel label="支店名 ※50文字以内" />
      <FormItemText
        v-model="bankBranchName"
        :errors="errors"
        form-id="bankBranchName"
        form-type="text"
      />
    </div>

    <div class="mb-8">
      <FormLabel label="種別(選択)" />
      <Select
        v-model="bankAccountType"
        :options="bankAccountTypes"
        :option-label="(t) => bankAccountTypeLabel(t)"
        :errors="errors"
        label="種別"
        form-id="bankAccountType"
        :invalid="!!errors.bankAccountType"
      >
      </Select>
    </div>

    <div class="mb-8">
      <FormLabel label="番号" />
      <FormItemText
        v-model="bankAccountNumber"
        :errors="errors"
        form-id="bankAccountNumber"
        form-type="text"
        autocomplete="bankAccountNumber"
      />
    </div>

    <div class="mb-8">
      <FormLabel label="口座名義" />
      <FormItemText
        v-model="bankAccountHolder"
        :errors="errors"
        form-id="bankAccountHolder"
        form-type="text"
        autocomplete="bankAccountHolder"
      />
    </div>

    <Card class="mb-8">
      <template #content>
        <!-- Business Accounts -->
        <div
          v-for="(acc, idx) in businessAccounts"
          :key="acc"
          class="border-b-2 mb-8"
        >
          <div class="mb-8">
            <FormLabel label="サービス名" />
            <FormItemText
              v-model="acc.serviceName"
              :errors="errors"
              :form-id="`businessAccounts[${idx}].serviceName`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="メールアドレス" />
            <FormItemEmail
              v-model="acc.email"
              :errors="errors"
              :form-id="`businessAccounts[${idx}].email`"
              form-type="email"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="パスワード" />
            <FormItemText
              v-model="acc.password"
              :errors="errors"
              :form-id="`businessAccounts[${idx}].password`"
              form-type="text"
            />
          </div>

          <div class="flex mb-8">
            <FormItemText
              v-model="acc.lastName"
              :errors="errors"
              label="姓"
              :form-id="`businessAccounts[${idx}].lastName`"
              form-type="text"
              class="me-1"
              style="max-width: 300px"
            />
            <FormItemText
              v-model="acc.firstName"
              :errors="errors"
              label="名"
              :form-id="`businessAccounts[${idx}].firstName`"
              form-type="text"
              class="ms-1"
              style="max-width: 300px"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="生年月日" />
            <FormItemDate
              v-model="acc.birthday"
              :errors="errors"
              :form-id="`businessAccounts[${idx}].birthday`"
              form-type="date"
              :start-date="fromISO('1990-01-01')"
              style="max-width: 210px"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="電話番号" />
            <FormItemText
              v-model="acc.phoneNumber"
              :errors="errors"
              :form-id="`businessAccounts[${idx}].phoneNumber`"
              form-type="text"
              autocomplete="bankAccountHolder"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="アカウント作成日" />
            <FormItemDate
              v-model="acc.createdOn"
              :errors="errors"
              :form-id="`businessAccounts[${idx}].createdOn`"
              form-type="date"
              style="max-width: 210px"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="アカウント失効日" />
            <FormItemDate
              v-model="acc.disusedOn"
              :errors="errors"
              :form-id="`businessAccounts[${idx}].discusedOn`"
              form-type="date"
              style="max-width: 210px"
            />
          </div>

          <div class="flex justify-end mb-8">
            <BasicButton
              icon="pi pi-trash"
              variant="danger"
              @click="removeBusinessAccount(idx)"
            >
            </BasicButton>
          </div>
        </div>
        <div class="flex justify-end mb-8">
          <BasicButton @click="addBusinessAccount">
            仕事用アカウントの追加
          </BasicButton>
        </div>
      </template>
    </Card>
    <Card>
      <template #content>
        <!-- Business Accounts -->
        <div
          v-for="(phone, idx) in businessPhones"
          :key="phone"
          class="border-b-2 mb-8"
        >
          <div class="mb-8">
            <FormLabel label="貸与日" />
            <FormItemDate
              v-model="phone.startOn"
              :errors="errors"
              :form-id="`businessPhone[${idx}].startOn`"
              form-type="date"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="返却日" />
            <FormItemDate
              v-model="phone.returnOn"
              :errors="errors"
              :form-id="`businessPhone[${idx}].returnOn`"
              form-type="date"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="sim契約日" />
            <FormItemDate
              v-model="phone.simStartOn"
              :errors="errors"
              :form-id="`businessPhone[${idx}].simStartOn`"
              form-type="date"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="最低利用日" />
            <FormItemDate
              v-model="phone.leastOn"
              :errors="errors"
              :form-id="`businessPhone[${idx}].leastOn`"
              form-type="date"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="番号" />
            <FormItemText
              v-model="phone.phoneNumber"
              :errors="errors"
              :form-id="`businessPhone[${idx}].phoneNumber`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="sim" />
            <Select
              v-model="phone.simType"
              :options="simTypes"
              :errors="errors"
              :form-id="`businessPhone[${idx}].simType`"
              :invalid="!!errors.simType"
            >
            </Select>
          </div>

          <div class="mb-8">
            <FormLabel label="会社" />
            <Select
              v-model="phone.simCompany"
              :options="simCompanyTypes"
              :errors="errors"
              :form-id="`businessPhone[${idx}].simCompany`"
              :invalid="!!errors.simCompany"
            >
            </Select>
          </div>

          <div class="mb-8">
            <FormLabel label="契約" />
            <Select
              v-model="phone.simContractType"
              :options="simContractTypes"
              :label="(t) => simContractTypeLabel(t)"
              :errors="errors"
              :form-id="`businessPhone[${idx}].simContractType`"
              :invalid="!!errors.simContractType"
            >
            </Select>
          </div>

          <div class="mb-8">
            <FormLabel label="契約内容" />
            <FormItemText
              v-model="phone.simContractDetails"
              :errors="errors"
              :form-id="`businessPhone[${idx}].simContractDetails`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="付与端末" />
            <FormItemText
              v-model="phone.deviceType"
              :errors="errors"
              :form-id="`businessPhone[${idx}].deviceType`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="容量" />
            <FormItemText
              v-model="phone.deviceCapacity"
              :errors="errors"
              :form-id="`businessPhone[${idx}].deviceCapacity`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="色" />
            <FormItemText
              v-model="phone.deviceColor"
              :errors="errors"
              :form-id="`businessPhone[${idx}].deviceColor`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="IMEI" />
            <FormItemText
              v-model="phone.imei"
              :errors="errors"
              :form-id="`businessPhone[${idx}].imei`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="ケース" />
            <FormItemText
              v-model="phone.case"
              :errors="errors"
              :form-id="`businessPhone[${idx}].case`"
              form-type="text"
            />
          </div>

          <div class="mb-8">
            <FormLabel label="パスコード" />
            <FormItemText
              v-model="phone.passcode"
              :errors="errors"
              :form-id="`businessPhone[${idx}].passcode`"
              form-type="text"
            />
          </div>

          <div class="flex justify-end mb-8">
            <BasicButton
              icon="pi pi-trash"
              variant="danger"
              @click="removeBusinessAccount(idx)"
            >
            </BasicButton>
          </div>
        </div>
        <div class="flex justify-end mb-8">
          <BasicButton @click="addBusinessPhone"> 端末情報の追加 </BasicButton>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { fromISO } from "/@/modules/luxon";
import { Label as FormLabel, BasicButton } from "/@/vue/components/Atom";
import {
  FormItemText,
  FormItemEmail,
  FormItemDate,
} from "/@/vue/components/Molecules/Form";
import {
  bankAccountTypes,
  BusinessPhone,
  simCompanyTypes,
  simContractTypeLabel,
  simContractTypes,
  SimContractTypes,
  simTypes,
} from "/@/types/organizationMember.d";
import {
  bankAccountTypeLabel,
  ZodFormattedErrors,
  BusinessAccount,
} from "/@/types/";
import Select from "primevue/select";
import Card from "primevue/card";

const code = defineModel<string | undefined>("code");
const bankName = defineModel<string | undefined>("bankName");
const bankBranchName = defineModel<string | undefined>("bankBranchName");
const bankAccountType = defineModel<string | undefined>("bankAccountType");
const bankAccountNumber = defineModel<string | undefined>("bankAccountNumber");
const bankAccountHolder = defineModel<string | undefined>("bankAccountHolder");

const businessAccounts = defineModel<BusinessAccount[]>("businessAccounts", {
  default: () => [],
});
const businessPhones = defineModel<BusinessPhone[]>("businessPhones", {
  default: () => [],
});

defineProps<{
  errors: ZodFormattedErrors;
}>();

function addBusinessAccount() {
  businessAccounts.value.push({
    serviceName: "",
    email: "",
    password: "",
    lastName: "",
    firstName: "",
    birthday: "",
    phoneNumber: "",
    createdOn: "",
    disusedOn: "",
  });
}

function removeBusinessAccount(idx: number) {
  businessAccounts.value.splice(idx, 1);
}

function addBusinessPhone() {
  businessPhones.value.push({
    startOn: "",
    returnOn: "",
    simStartOn: "",
    leastOn: "",
    phoneNumber: "",
    simType: simTypes[0],
    simCompany: simCompanyTypes[0],
    simContractType: simContractTypes[0],
    simContractDetails: "",
    deviceType: "",
    deviceCapacity: "",
    deviceColor: "",
    imei: "",
    case: "",
    passcode: "",
  });
}

function removeBusinessPhone(idx: number) {
  businessPhones.value.splice(idx, 1);
}
</script>

<style scoped></style>
