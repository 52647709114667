export function isImage(filename: string) {
  return /\.(jpe?g|png|gif)$/i.test(filename);
}

export function extractExtension(filename: string) {
  return filename.split(".").pop();
}

export function extractFilename(filename: string) {
  return filename.split(".")[0];
}

// content type

export function isImageContentType(contentType: string) {
  return contentType.startsWith("image");
}
