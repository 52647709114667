<template>
  <div>Work Log (編集リクエストテスト)</div>

  <Table
    v-model:per-page="selectedPerPage"
    v-model:current-page="currentPage"
    :work-logs="workLogs || []"
    :total-pages="1"
    @edit="edit"
  />
</template>

<script setup lang="ts">
import { useTableHeader, useWorkLog, useRouterUtil } from "/@/vue/composables";
import { Table } from "/@/vue/components/Organisms/WorkLogs";

const { goto } = useRouterUtil();

const { getWorkLogs } = useWorkLog();
const { data: workLogs } = getWorkLogs();

const { currentPage, selectedPerPage } = useTableHeader();

function edit(id: number) {
  goto({ name: "UsersWorkLogsEdit", params: { id: id } });
}
</script>

<style scoped></style>
