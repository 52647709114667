<template>
  <div>
    <FormItemWrapper :form-id="formId" :errors="errors" :label="label">
      <InputForm
        v-model="modelValue"
        :is-valid="!errors[formId]"
        :autocomplete="autocomplete"
        class="w-full"
        @cleared="modelValue = undefined"
      />
    </FormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { InputForm } from "/@/vue/components/Atom/Form";
import { FormItemWrapper } from "/@/vue/components/Molecules";
import { ZodFormattedErrors } from "/@/types";

defineProps<{
  formId: string;
  label?: string;
  autocomplete?: string;
  errors: ZodFormattedErrors;
}>();

const modelValue = defineModel<string>();
</script>

<style scoped></style>
