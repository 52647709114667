<template>
  <div>
    <FormItemWrapper :form-id="formId" :errors="errors" :label="label">
      <TimeForm
        v-model="modelValue"
        :is-valid="!errors[formId]"
        :teleport-center="teleportCenter"
        :minutes-increment="minutesIncrement"
        :env="env"
        :disabled="disabled"
        @cleared="modelValue = undefined"
      />
    </FormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { LuxonTimeObject } from "/@/modules/luxon";
import { TimeForm } from "/@/vue/components/Atom";
import { FormItemWrapper } from "/@/vue/components/Molecules";
import { ZodFormattedErrors } from "/@/types";

defineProps<{
  formId: string;
  label?: string;
  env?: LuxonTimeObject;
  minutesIncrement?: number;
  teleportCenter?: boolean;
  disabled?: boolean;
  errors: ZodFormattedErrors;
}>();

const modelValue = defineModel<string | undefined>();
</script>

<style scoped></style>
