<template>
  <Button
    :class="btnClass"
    :severity="severity"
    :disabled="disabledInComponent || disabled"
    :label="label"
    :name="name"
    :type="buttonType"
    :icon="icon"
    :size="size"
    :rounded="rounded"
    :text="text"
    :tabindex="tabindex"
    @click="onClick"
  >
    <slot></slot>
  </Button>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, ButtonHTMLAttributes } from "vue";
import Button from "primevue/button";
type ButtonTypes = ButtonHTMLAttributes["type"];

interface Props {
  name?: string;
  variant?: "danger" | "primary" | "secondary" | "warning" | "gray" | "success";
  label?: string;
  disabled?: boolean;
  circle?: boolean;
  large?: boolean;
  small?: boolean;
  middle?: boolean;
  slim?: boolean;
  auto?: boolean;
  icon?: string;
  rounded?: boolean;
  withShadow?: boolean;
  buttonType?: ButtonTypes;
  text?: boolean;
  w300?: boolean;
  stopDualClick?: boolean;
  tabindex?: number;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  variant: undefined,
  buttonType: "button",
  withShadow: false,
  icon: undefined,
  label: undefined,
});

const emit = defineEmits<{
  (e: "click"): void;
}>();

onMounted(() => {});

const btnClass = computed(() => ({
  "drop-shadow": props.withShadow,
  "btn-circle": props.circle,
  "btn-slim": props.slim,
  "btn-auto": props.auto,
  "w-300": props.w300,
}));

const severity = computed(() => {
  return props.variant;
});

const size = computed(() => {
  if (props.small) {
    return "small";
  }

  if (props.large) {
    return "large";
  }

  return undefined;
});

const disabledInComponent = ref<boolean>(false);

function onClick(e: MouseEvent) {
  e.stopPropagation();
  emit("click");
  if (props.stopDualClick || props.buttonType === "submit") {
    const sleep = (msec: number) =>
      new Promise((resolve) => setTimeout(resolve, msec));

    (async () => {
      disabledInComponent.value = true;
      await sleep(3000);
      disabledInComponent.value = false;
    })();
  }
}
</script>

<style lang="scss" scoped></style>
