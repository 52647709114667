<template>
  <div>
    <Select
      :model-value="{ year: year, month: month }"
      :option-label="
        (s) => (s.year && s.month ? s.year + '年' + s.month + '月' : 'すべて')
      "
      :options="[{ year: undefined, month: undefined }, ...existsMonths]"
      style="min-width: 150px"
      @update:model-value="updateSelected"
    />
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed, onMounted } from "vue";
import { DateTime } from "luxon";
import { createExistsDateMap } from "/@/modules/calendar";
import type { DateMap } from "/@/types";
import Select from "primevue/select";

interface Props {
  month?: number;
  year?: number;
  existsDates: string[];
  disabled?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  withResetBtn?: boolean;
  disabledAutoSelect?: boolean;
  hideMonthChangeBtn?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  day: undefined,
  month: undefined,
  year: undefined,
  maxDate: undefined,
  minDate: undefined,
});

const emit = defineEmits<{
  (e: "update:month", month: number | undefined): void;
  (e: "update:year", year: number | undefined): void;
}>();

function reset() {
  emit("update:year", undefined);
  emit("update:month", undefined);
}

function updateSelected(selected: { year: number; month: number } | undefined) {
  if (!selected) {
    reset();
    return;
  }

  emit("update:year", selected.year);
  emit("update:month", selected.month);
}

// pdatepicker

const existsMonths = computed<DateMap[]>(() => {
  if (!props.existsDates) {
    return [];
  }

  return _.uniqBy(
    createExistsDateMap(props.existsDates),
    (item) => `${item.year}-${item.month}`
  ).toSorted((a, b) => {
    if (a.year === b.year) {
      return a.month - b.month;
    }

    return a.year - b.year;
  });
});
</script>

<style lang="scss" scoped></style>
