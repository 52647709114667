<template>
  <div>
    <div class="flex items-center justify-between mb-8">
      <div class="flex items-center">
        <SelectObject
          v-if="organizations && organizations.length"
          v-model="selectedOrganizationId"
          option-label="name"
          option-value="id"
          :options="organizations"
          class="me-4"
        />

        <SelectObject
          v-if="organizationMembers && organizationMembers.length"
          v-model="selectedOrganizationMemberId"
          :option-label="
            (member) => member.user?.lastName + member.user?.firstName
          "
          option-value="id"
          :options="organizationMembers"
          class="me-4"
        />

        <SelectDate
          v-model:year="selectedDate.year"
          v-model:month="selectedDate.month"
          :existsDates="existsDates"
        />
      </div>

      <BasicButton label="印刷" icon="pi pi-print" />
    </div>

    <div
      v-if="attendances && attendances.length"
      class="bg-white dark:bg-gray-800 p-4 rounded-md"
    >
      <DataTable :value="dates" tableStyle="min-width: 50rem">
        <Column field="dateKey" header="日にち" style="width: 125px">
          <template #body="slotProps">
            <Tag
              :value="basicFormatter(slotProps.data.dateKey, 'noYear')"
              severity="secondary"
            />
          </template>
        </Column>
        <Column field="startAt" header="時刻" style="width: 130px">
          <template #body="slotProps">
            <template v-if="eachDateAttendance[slotProps.data.dateKey]">
              <div
                v-for="(dk, idx) in eachDateAttendance[slotProps.data.dateKey]"
                :key="slotProps.data.dateKey + 'startAt'"
                class="flex"
                :class="{ 'mt-4': idx !== 0 }"
              >
                <Tag
                  v-if="dk.attendanceType === 'holiday'"
                  value="欠勤"
                  severity="danger"
                />
                <Tag
                  v-else-if="dk.attendanceType === 'paid_holiday'"
                  value="有休"
                  severity="danger"
                />
                <Tag
                  v-else
                  severity="info"
                  :value="`${basicFormatter(dk.startAt, 'onlyTime')}~${
                    dk.finishAt
                      ? basicFormatter(dk.finishAt, 'onlyTime')
                      : '勤務中'
                  }`"
                  class="me-2"
                />
              </div>
            </template>
          </template>
        </Column>
        <Column header="詳細">
          <template #body="slotProps">
            <template v-if="eachDateAttendance[slotProps.data.dateKey]">
              <div
                v-for="(dk, idx) in eachDateAttendance[slotProps.data.dateKey]"
                :key="dk.id"
                class="flex"
              >
                <template v-if="dk.attendanceType === 'holiday'">
                  <span class="me-2">
                    {{ absenceTypesLabel(dk.absenceType) }}
                  </span>
                  <span class="me-2"> {{ dk.bodyTemperature }}℃ </span>
                  <span>
                    {{ dk.explanation }}
                  </span>
                </template>
                <template v-else>
                  <span v-if="dk.evaluationType">
                    <Tag
                      :value="evaluationTypeLabel(dk.evaluationType)"
                      class="me-2"
                    />
                  </span>
                  <span v-if="dk.report">
                    {{ dk.report }}
                  </span>
                </template>
              </div>
            </template>
          </template>
        </Column>
        <Column>
          <template #body="{ data }">
            <div v-for="at in eachDateAttendance[data.dateKey]" :key="at">
              <BasicButton
                label="編集"
                icon="pi pi-pencil"
                class="me-2"
                @click="openEditModal(at.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <Card v-else>
      <template #content> 選択した月のデータがありません </template>
    </Card>

    <SimpleModal v-model:visible="showEditModal" title="変更依頼">
      <AttendanceForm
        v-if="selectedAttendance"
        v-model:evaluation-type="form.evaluationType"
        v-model:report="form.report"
        v-model:start-at="form.correctionStartAt"
        v-model:finish-at="form.correctionFinishAt"
        from-edit-modal
        :current-attendance="selectedAttendance"
        :errors="errors"
        class="mb-8"
      />
      <div class="flex justify-end">
        <BasicButton @click="update"> 変更依頼 </BasicButton>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive } from "vue";
import {
  basicFormatter,
  fromISO,
  luxonNow,
  existsDates,
} from "/@/modules/luxon";
import { createDateForCalendars } from "/@/modules/calendar";
import {
  useRouterUtil,
  useUser,
  useOrganizationMember,
  useAttendance,
  useZodScheme,
} from "/@/vue/composables";
import { BasicButton, SelectObject, SelectDate } from "/@/vue/components/Atom";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import { Form as AttendanceForm } from "/@/vue/components/Organisms/Attendances";
import {
  absenceTypesLabel,
  AttendanceCheckScheme,
  AttendanceClient,
  AttendanceForm as AttendanceFormType,
  DateMap,
  evaluationTypeLabel,
} from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Card from "primevue/card";

const { goto } = useRouterUtil();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser({
  withTodayShifts: true,
});

// organization

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const selectedOrganizationId = ref<number>();
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

const { getOrganizationMembers } = useOrganizationMember();
const { data: organizationMembers } = getOrganizationMembers(
  selectedOrganizationId
);

const selectedOrganizationMemberId = ref<number>();
const selectedOrganizationMember = computed(() => {
  if (!organizationMembers.value) return;
  return organizationMembers.value.find(
    (m) => m.id === selectedOrganizationMemberId.value
  );
});

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

const dates = computed(() => {
  return createDateForCalendars(selectedDate);
});

// attendances

const { getAttendances } = useAttendance();
const { data: attendances } = getAttendances(
  selectedOrganizationMemberId,
  selectedDate
);

const eachDateAttendance = computed(() => {
  if (attendances.value && attendances.value.length && dates.value.length) {
    let m = {};

    dates.value.forEach((d) => {
      m[d.dateKey] = attendances.value.filter(
        (a) => basicFormatter(a.startAt, "iso") === d.dateKey
      );
    });

    return m;
  } else {
    return {};
  }
});

// form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<AttendanceFormType>(
  AttendanceCheckScheme
);

function resetForm(attendance?: AttendanceClient) {
  form.id = attendance?.id || undefined;
  form.evaluationType = attendance?.evaluationType || "excellent";
  form.report = attendance?.report || undefined;
  form.correctionStartAt = today.set({ hour: 9, minute: 0 }).toISO();
  form.correctionFinishAt = today.set({ hour: 18, minute: 0 }).toISO();
}

// modal

const showEditModal = ref(false);

const selectedAttendanceId = ref<number>();
const selectedAttendance = computed(() => {
  if (!attendances.value) return;
  return attendances.value.find((a) => a.id === selectedAttendanceId.value);
});

function openEditModal(id: number) {
  showEditModal.value = true;

  selectedAttendanceId.value = id;
  resetForm(selectedAttendance.value);
}

const { update: updateAttendance } = useAttendance();

async function update() {
  if (!selectedAttendance.value) return;

  if (await updateAttendance(form)) {
    alert("変更依頼を作成しました");
  } else {
    alert("業務終了に失敗しました");
  }
}
</script>

<style scoped></style>
