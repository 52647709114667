<template>
  <Card title="写真報告" :variant="variant">
    <template #content>
      <div class="flex justify-end">
        <BasicButton
          :disabled="!photoURL"
          @click="
            clearPhoto();
            openModal();
          "
        >
          <div class="me-2">
            <i v-if="photoURL" class="pi pi-refresh" />
            <i v-else class="pi pi-refresh text-gray-500" />
          </div>

          <div>撮り直す</div>
        </BasicButton>
      </div>

      <div class="mt-4">
        <BasicButton
          v-if="!photoURL"
          :class="`w-full h-40 ${isValid ? '' : 'in-valid'}`"
          role="button"
          @click="
            openModal();
            photoInit();
          "
        >
          <i class="pi pi-camera"></i>
          撮影する
        </BasicButton>
        <div v-else class="flex justify-center w-full">
          <Card>
            <template #content>
              <img
                :src="photoURL"
                class="card drop-shadow preview"
                height="300"
                width="400"
              />
            </template>
          </Card>
        </div>
      </div>
    </template>
  </Card>

  <teleport to="body">
    <SimpleModal v-model:visible="modalVisible" title="写真の撮影">
      <div class="w-100 flex items-center justify-center mb-8">
        <Card>
          <template #content>
            <video
              v-if="!photoImage"
              :id="`video${photoId}`"
              class="card"
              muted
              autoplay
              playsinline
              :style="{ 'max-height': '250px', 'object-fit': 'contain' }"
            ></video>
          </template>
        </Card>
      </div>
      <canvas
        v-show="false"
        :id="`photoCanvas${photoId}`"
        class="card w-100"
      ></canvas>
      <div class="flex justify-end">
        <BasicButton
          label="キャンセル"
          variant="secondary"
          class="me-2"
          @click="clearPhoto()"
        />
        <BasicButton
          :label="photo ? '撮影' : '撮影'"
          icon="pi pi-camera"
          @click="takePhotoAndClose()"
        />
      </div>
    </SimpleModal>
  </teleport>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import {
  getCameraStream,
  registerVideStream,
  getTrackSizeFromVideoStream,
  transcriptionPhotoFromVideoElementToCanvasElement,
} from "/@/modules/stream";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";

import { BasicButton } from "/@/vue/components/Atom";
import { PhotoDataObject } from "/@/types";
import Card from "primevue/card";

const photo = defineModel<PhotoDataObject | undefined>("photo");

defineProps<{
  variant?: string;
  isValid?: boolean;
}>();

const videoStream = ref<MediaStream>();
const videoElement = ref<HTMLVideoElement>();
const photoImage = ref();
const photoId = "a" + uuidv4();

async function photoInit() {
  const stream = await getCameraStream();

  if (!stream) {
    alert("カメラの起動に失敗しました。カメラの起動が必須です。");
    return;
  }

  videoStream.value = stream;

  const video = registerVideStream(stream, `video${photoId}`);

  videoElement.value = video;
}

const photoCanvas = ref<HTMLCanvasElement>();
const photoURL = ref();

async function takePhoto() {
  if (!videoElement.value || !videoStream.value) {
    alert("予期せぬエラーが発生しました。");
    return;
  }

  const canvas = document.getElementById(`photoCanvas${photoId}`);

  if (!(canvas instanceof HTMLCanvasElement)) {
    return;
  }

  const { width, height } = getTrackSizeFromVideoStream(videoStream.value);

  if (width && height) {
    const { data, blob } =
      await transcriptionPhotoFromVideoElementToCanvasElement(
        videoElement.value,
        canvas,
        width,
        height
      );

    if (!data) {
      alert("写真の撮影に失敗しました。");
      return;
    }

    photo.value = { data: data };

    photoImage.value = blob;
    photoURL.value = data;
    photoCanvas.value = canvas;
  }
}

function takePhotoAndClose() {
  takePhoto();
  closeModal();
}

function clearPhoto() {
  photo.value = undefined;
  if (photoCanvas.value) {
    photoCanvas.value.innerHTML = "";
  }

  photoURL.value = undefined;
  photoInit();
  photoImage.value = undefined;
  closeModal();
}

// modal

const modalVisible = ref(false);

async function openModal() {
  modalVisible.value = true;
}

function closeModal() {
  modalVisible.value = false;

  if (videoStream.value?.getTracks()) {
    videoStream.value.getTracks().forEach((track) => track.stop());
  }
}
</script>

<style lang="scss" scoped></style>
