<template>
  <div>
    <FormItemWrapper :form-id="formId" :errors="errors" :label="label">
      <EmailForm
        v-model="modelValue"
        :form-id="formId"
        :is-valid="!errors[formId]"
        class="w-full"
        @cleared="modelValue = undefined"
      />
    </FormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { EmailForm } from "/@/vue/components/Atom";
import { FormItemWrapper } from "/@/vue/components/Molecules";
import { ZodFormattedErrors } from "/@/types";

defineProps<{
  formId: string;
  label?: string;
  errors: ZodFormattedErrors;
}>();

const modelValue = defineModel<string | undefined>();
</script>

<style scoped></style>
