<template>
  <div>
    <Card>
      <template #content>
        <Badge
          :value="approvalRequestStatusLabel(approvalRequest.approvalStatus)"
          :severity="badgeSeverity[approvalRequest.approvalStatus] || 'info'"
          class="mb-8"
        />

        <div>
          <template v-if="isMemberApplication">
            <div class="flex flex-wrap">
              <span class="me-4 mb-8">
                {{
                  memberTypeLabel(approvalRequest.dependancy.applicationType)
                }}
              </span>
              <span v-if="!readonly" class="me-4">
                {{ approvalRequest.dependancy.userName }}
              </span>
              <span v-if="approvalRequest.dependancy?.date">
                {{ basicFormatter(approvalRequest.dependancy.date) }}
              </span>
              <div
                v-if="
                  approvalRequest.dependancy?.correctionStartAt &&
                  approvalRequest.dependancy?.correctionFinishAt
                "
                class="w-full mb-8"
              >
                <div class="flex mb-8">
                  <Badge value="修正前" severity="success" class="me-4" />
                  <span>
                    {{
                      basicFormatter(
                        approvalRequest.dependancy.currentStartAt,
                        "withTime"
                      )
                    }}〜{{
                      basicFormatter(
                        approvalRequest.dependancy.currentFinishAt,
                        "onlyTime"
                      )
                    }}
                  </span>
                </div>
                <div class="flex">
                  <Badge value="修正後" severity="danger" class="me-4" />
                  <span>
                    {{
                      basicFormatter(
                        approvalRequest.dependancy.correctionStartAt,
                        "withTime"
                      )
                    }}〜{{
                      basicFormatter(
                        approvalRequest.dependancy.correctionFinishAt,
                        "onlyTime"
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <div v-if="!readonly" class="flex items-center justify-end">
              <BasicButton
                v-if="approvalRequest.approvalStatus === 'pending'"
                variant="danger"
                icon="pi pi-times"
                label="拒否"
                class="me-4"
                @click="$emit('reject', approvalRequest.id)"
              />

              <BasicButton
                v-if="approvalRequest.approvalStatus === 'pending'"
                variant="success"
                icon="pi pi-check"
                label="承認"
                @click="$emit('approve', approvalRequest.id)"
              />
            </div>
          </template>

          <Accordion v-if="false">
            <AccordionPanel value="0" class="border-0">
              <AccordionHeader> アクション </AccordionHeader>
              <AccordianContent> </AccordianContent>
            </AccordionPanel>
          </Accordion>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import {
  ApprovalRequestClient,
  approvalRequestStatusLabel,
  memberTypeLabel,
} from "/@/types";
import Badge from "primevue/badge";
import Card from "primevue/card";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordianContent from "primevue/accordioncontent";
import { basicFormatter } from "/@/modules/luxon";
import primevueOptions from "/@/entrypoints/primevueOptions";

const props = defineProps<{
  approvalRequest: ApprovalRequestClient;
  readonly?: boolean;
}>();

defineEmits<{
  (e: "approve", id: number): void;
  (e: "reject", id: number): void;
}>();

const isMemberApplication = computed(() => {
  return (
    props.approvalRequest.dependancyType === "MemberApplication" &&
    props.approvalRequest.dependancy
  );
});

const isHolidayApplication = computed(() => {
  return (
    isMemberApplication.value &&
    props.approvalRequest.dependancy?.applicationType === "holiday"
  );
});

const isPaidApplication = computed(() => {
  return (
    isMemberApplication.value &&
    props.approvalRequest.dependancy?.applicationType === "paid"
  );
});

const badgeSeverity = reactive({
  pending: "warning",
  approved: "success",
  rejected: "danger",
});
</script>

<style scoped></style>
