import { z } from "/@/modules/zodUtils";

export type JpHolidays = Partial<Record<string, string>> | {};

export const DateForCalendarScheme = z.object({
  dateKey: z.string(),
  luxonDate: z.custom((value) => {
    if (value instanceof DateTime) {
      return value;
    } else {
      throw new Error("Invalid luxon date object");
    }
  }),
  weekday: z.number().min(1).max(7),
  dayNumber: z.number().min(1).max(31),
});

export type DateForCalendar = {
  dateKey: string;
  luxonDate: DateTime;
  weekday: number;
  dayNumber: number;
};
