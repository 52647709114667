<template>
  <div id="header">
    <Menubar :model="headerItems">
      <template #item="{ item, props, hasSubmenu }">
        <router-link
          v-if="item.visible && item.to"
          v-slot="{ href, navigate }"
          :to="item.to"
          custom
        >
          <a :href="href" v-bind="props.action" @click="navigate">
            <i v-if="item.icon" :class="item.icon"></i>
            <span :class="{ 'ml-2': item.icon }">{{ item.label }}</span>
          </a>
        </router-link>
        <a
          v-else-if="item.visible && item.url"
          :href="item.url"
          :target="item.target"
          v-bind="props.action"
        >
          <i v-if="item.icon" :class="item.icon"></i>
          <span :class="{ 'ml-2': item.icon }">{{ item.label }}</span>
          <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
      </template>
    </Menubar>
  </div>
</template>

<script lang="ts" setup>
import type { User } from "/@/types/user";
import Menubar from "primevue/menubar";
import { computed } from "vue";

const props = defineProps<{
  user?: User;
}>();

defineEmits(["logout"]);

const headerItems = computed(() => [
  { label: "サイトトップ", to: { name: "Index" }, visible: true },
  { label: "会員トップ", to: { name: "UsersIndex" }, visible: true },
  { label: "ログイン", to: { name: "SignIn" }, visible: !props.user },
  { label: "アカウント作成", to: { name: "SignUp" }, visible: false },
  { label: "ログアウト", icon: "pi pi-sign-out", visible: !!props.user },
]);
</script>

<style lang="scss" scoped></style>
