import useSWRV from "swrv";
import { Ref } from "vue";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { DateMap } from "/@/types";
import { ApprovalRequestClient } from "/@/types/approvalRequest.d";

export function useApprovalRequests() {
  function getApprovalRequests(
    organizationId: Ref<number | undefined>,
    selectedDate: DateMap
  ) {
    function getKey() {
      if (!organizationId.value) {
        return null;
      }

      let key = `/api/v1/organizations/${organizationId.value}/approval_requests`;

      if (selectedDate.year && selectedDate.month) {
        key += `?year=${selectedDate.year}&month=${selectedDate.month}`;
      }

      return key;
    }

    return useSWRV<ApprovalRequestClient[]>(() => getKey(), fetcher);
  }

  async function processApprovalRequest(
    id: number,
    processType: "approve" | "reject"
  ) {
    try {
      await axios.post(`/api/v1/approval_requests/${id}/${processType}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getApprovalRequests,
    processApprovalRequest,
  };
}
