<template>
  <div
    id="session-sign-in"
    class="flex justify-center items-center content-center h-full"
  >
    <LoginForm @login="login" class="min-w-96 max-w-full w-8/12">
      <MessageList
        v-if="errors.length"
        :messages="errors"
        status="error"
        id="sign-in-errors"
      />
    </LoginForm>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useAuthorize, useRouterUtil } from "/@/vue/composables";
import { MessageList } from "/@/vue/components/Atom";
import { LoginForm } from "/@/vue/components/Organisms";
import { User } from "/@/types";

// login

const { login: userLogin } = useAuthorize();
const { goto } = useRouterUtil();

const loading = ref<boolean>(false);
const errors = ref<Array<string>>([]);

async function login(signinForm: User) {
  loading.value = true;

  if (await userLogin(signinForm)) {
    goto({ name: "UsersIndex" });
  } else {
    errors.value = [
      "ログインに失敗しました。IDとパスワードを確認してください。",
    ];
  }
  loading.value = false;
}
</script>
