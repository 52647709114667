<template>
  <div id="user-index">
    <template v-if="homeInfo">
      <div class="flex flex-col items-center justify-center w-full">
        <Card class="w-full max-w-2xl">
          <template #title> 今日の情報 </template>
          <template #content>
            <CurrentStatus :homeInfo="homeInfo" class="mb-8" />
          </template>
          <template #footer>
            <div class="w-full flex items-center justify-center">
              <Operations
                class="w-full max-w-80"
                @start="start"
                @finish="finish"
                @absence="absence"
                :status-attributes="statusAttributes"
              />
            </div>
          </template>
        </Card>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUser, useRouterUtil } from "/@/vue/composables";
import {
  ShowFormItemEmail,
  ShowFormItemWrapper,
} from "/@/vue/components/Molecules/Form";
import { Operations } from "/@/vue/components/Organisms/Users";
import { CurrentStatus } from "/@/vue/components/Organisms/Attendances";
import Card from "primevue/card";

const { goto } = useRouterUtil();

// user

const { getCurrentUser, getHome } = useUser();
const { data: user } = getCurrentUser();

const statusAttributes = computed(() => {
  if (!user.value) return {};
  return user.value.statusAttributes;
});

// api

const { data: homeInfo } = getHome();

const currentAttendance = computed(() => {
  if (!homeInfo.value) return;
  return homeInfo.value.currentAttendance;
});

const todayFinishedAttendances = computed(() => {
  if (!homeInfo.value) return [];
  return homeInfo.value.todayFinishedAttendances;
});

// operations

function start() {
  goto({ name: "UsersAttendancesNew" });
}

function finish() {
  if (!currentAttendance.value) return;

  goto({
    name: "UsersAttendancesEdit",
    params: { id: currentAttendance.value.id },
  });
}

function absence() {
  goto({
    name: "UsersAttendancesAbsence",
  });
}
</script>

<style scoped lang="scss"></style>
