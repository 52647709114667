<template>
  <div id="user-header" class="z-50">
    <Toolbar>
      <template #start>
        <div class="flex justify-center">
          <div class="flex items-center">
            <BasicButton
              v-if="user"
              icon="pi pi-bars"
              class="me-2 menu-button"
              need-icon
              variant="secondary"
              outlined
              large
              @click="showHeader = !showHeader"
            />
            <h5 class="flex items-center">
              <PageTree v-if="pageTree.length" :tree="pageTree" />
              <b id="page-title">{{ pageTitle }}</b>
            </h5>
          </div>
        </div>
      </template>
    </Toolbar>
    <Drawer v-if="user" v-model:visible="showHeader" id="header-drawer">
      <TieredMenu :model="headerItems" class="without-border w-100" />
    </Drawer>
  </div>
</template>

<script lang="ts" setup>
import Drawer from "primevue/drawer";
import TieredMenu from "primevue/tieredmenu";
import Toolbar from "primevue/toolbar";
import type { MenuItem } from "primevue/menuitem";
import { ref, computed } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import { PageTree } from "/@/vue/components/Layouts";
import { useRouterUtil, useAuthorize, useUser } from "/@/vue/composables";
import type { UserClient } from "/@/types/user";

interface Props {
  user?: UserClient;
  isAdminPage?: boolean;
}

const props = defineProps<Props>();
defineEmits(["logout", "hideHeader"]);

const showHeader = ref<boolean>(false);

const { goto, reload, currentRouteMeta } = useRouterUtil();

const pageTitle = computed(() => {
  /*
  if (currentRouteQuery.value?.title) {
    return currentRouteQuery.value.title;
  }
  */
  return currentRouteMeta.value?.title;
});

const pageTree = computed(() => {
  /*
  if (currentRouteQuery.value?.hoge) {
    return [
      { title: "hoge", name: "Index" }
    ]
  }
  */
  return currentRouteMeta.value?.tree || [];
});

// header items

function commandHandler(command: () => void) {
  return () => {
    command();
    showHeader.value = false;
  };
}

const labelAttributes = computed(() => {
  if (!props.user) {
    return {};
  }

  return props.user.labelAttributes;
});

const headerItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "管理画面",
      icon: "pi pi-cog",
      visible: !!labelAttributes.value.systemAdmin,
      command: commandHandler(() => goto({ name: "AdminIndex" })),
    },
    {
      label: "ホーム",
      icon: "pi pi-home",
      visible: !!props.user,
      command: commandHandler(() => goto({ name: "UsersIndex" })),
    },
    {
      label: "カレンダー",
      icon: "pi pi-calendar",
      visible: !!props.user,
      command: commandHandler(() => goto({ name: "UsersCalendarIndex" })),
    },
    {
      label: "業務計画",
      icon: "pi pi-list",
      visible: !!props.user && false,
      command: commandHandler(() => goto({ name: "UsersPlansIndex" })),
    },
    {
      label: "業務記録",
      icon: "pi pi-book",
      visible: !!props.user && false,
      command: commandHandler(() => goto({ name: "UsersWorkLogsIndex" })),
    },
    {
      label: "出勤簿",
      icon: "pi pi-address-book",
      visible: !!props.user,
      command: commandHandler(() => goto({ name: "UsersAttendancesIndex" })),
    },
    {
      label: "労務管理",
      icon: "pi pi-briefcase",
      visible: !!props.user,
      command: commandHandler(() =>
        goto({ name: "UsersLaborManagementsIndex" })
      ),
    },
    {
      label: "組織情報・管理",
      icon: "pi pi-users",
      visible: !!props.user,
      command: commandHandler(() => goto({ name: "UsersOrganizationsIndex" })),
    },
    {
      label: "承認リクエスト一覧",
      icon: "pi pi-check",
      visible: !!props.user,
      command: commandHandler(() =>
        goto({ name: "UsersApprovalRequestsIndex" })
      ),
    },
    {
      label: "資料共有",
      icon: "pi pi-file",
      visible: !!props.user && false,
      command: commandHandler(() => goto({ name: "UsersArticlesIndex" })),
    },
    {
      label: "サイトトップ",
      icon: "pi pi-th-large",
      visible: !!props.user,
      command: commandHandler(() => goto({ name: "Index" })),
    },
    {
      label: "ログアウト",
      icon: "pi pi-sign-out",
      visible: !!props.user,
      command: commandHandler(() => handleLogout()),
    },
  ];
});

// logout

const { logout } = useAuthorize();

async function handleLogout() {
  await logout();
  reload();
}
</script>

<style lang="scss">
#user-header {
  .p-toolbar {
    border: none;
  }

  .p-button {
    border: none;
  }
}

#header-drawer {
  .p-tieredmenu {
    border: none;
  }

  .p-drawer-content {
    --p-drawer-content-padding: 0.5rem;
    --p-tieredmenu-item-padding: 1rem 0.75rem;
  }
}
</style>
