<template>
  <div>
    <OrganizationForm
      v-model:name="form.name"
      v-model:phone-number="form.phoneNumber"
      v-model:postal-code="form.postalCode"
      v-model:address-level1="form.addressLevel1"
      v-model:address-level2="form.addressLevel2"
      v-model:address-line1="form.addressLine1"
      v-model:address-line2="form.addressLine2"
      :errors="errors"
    />

    <div class="flex justify-end w-full">
      <BasicButton
        label="キャンセル"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto"
      />
      <BasicButton label="更新" button-type="submit" @click="submit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { getAddress } from "/@/modules/address";
import {
  useRouterUtil,
  useUser,
  useZodScheme,
  useOrganization,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as OrganizationForm } from "/@/vue/components/Organisms/Organizations";
import {
  OrganizationForm as OrganizationFormType,
  OrganizationCheckScheme,
  OrganizationClient,
} from "/@/types";
import { gPhoneNumber, gPostcode } from "/@/modules/groomer";

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});

const { currentRouteParams, backto, goto } = useRouterUtil();
const id = computed(() => Number(currentRouteParams.value.id));

const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === id.value);
});

watch(selectedOrganization, (n) => {
  if (!form.id && n) {
    resetForm(n);
  }
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationFormType>(OrganizationCheckScheme);

watch(
  () => form.postalCode,
  async (p, old) => {
    if (p !== old) {
      const res = await getAddress(p);

      if (res) {
        const { pref, city, town } = res;
        form.addressLevel1 = pref;
        form.addressLevel2 = city;
        form.addressLine1 = town;
      }
    }
  }
);

watch(form, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.phoneNumber) {
    form.phoneNumber = gPhoneNumber(f.phoneNumber);
  }

  if (f.postalCode) {
    form.postalCode = gPostcode(f.postalCode);
  }
});

function resetForm(organization?: OrganizationClient) {
  form.id = organization?.id || undefined;
  form.name = organization?.name || undefined;
  form.phoneNumber = organization?.phoneNumber || undefined;
  form.postalCode = organization?.postalCode || undefined;
  form.addressLevel1 = organization?.addressLevel1 || undefined;
  form.addressLevel2 = organization?.addressLevel2 || undefined;
  form.addressLine1 = organization?.addressLine1 || undefined;
  form.addressLine2 = organization?.addressLine2 || undefined;
}

onMounted(() => {
  startValidation.value = true;
  if (selectedOrganization.value) {
    resetForm(selectedOrganization.value);
  }
});

const { updateOrganization } = useOrganization();

async function submit() {
  try {
    startValidation.value = true;

    const args = OrganizationCheckScheme.parse(form);

    if (await updateOrganization(id.value, args)) {
      alert("更新しました");
      goto({ name: "UsersOrganizationsIndex" });
    } else {
      alert("更新に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
