import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "AdminIndex",
    component: Index,
    meta: {
      title: "管理TOP",
      tree: [],
    },
  },
];
