import zod, { date } from "zod";
import { z } from "/@/modules/zodUtils";

export const shiftTypes = [
  "fulltime",
  "hourly_work",
  "holiday",
  "half_holiday",
  "hourly_holiday",
  "paid_holiday",
] as const;
export const ShiftTypeScheme = z.enum(shiftTypes);
export type ShiftType = zod.infer<typeof ShiftTypeScheme>;

export function shiftTypeLabel(type: ShiftType): string {
  // 出勤 半勤 休日 有休
  switch (type) {
    case "fulltime":
      return "出勤";
    case "halftime":
      return "半勤";
    case "hourly_work":
      return "時間勤";
    case "holiday":
      return "全休";
    case "half_holiday":
      return "半休";
    case "hourly_holiday":
      return "時間休";
    case "paid_holiday":
      return "有休";
  }
}

export const shiftContentTypes = [
  // 出社 出張 在宅 現場
  "office",
  "trip",
  "remote",
  "site",
  "other",
] as const;
export const ShiftContentTypeScheme = z.enum(shiftContentTypes);
export type ShiftContentType = zod.infer<typeof ShiftContentTypeScheme>;

export function shiftContentTypeLabel(type: ShiftContentType): string {
  // 通常 出張 在宅
  switch (type) {
    case "office":
      return "出社";
    case "trip":
      return "出張";
    case "remote":
      return "在宅";
    case "site":
      return "現場";
    case "other":
      return "その他";
  }
}

export const ShiftScheme = z.object({
  id: z.number().optional(),
  organizationMemberId: z.number().optional(),
  shiftType: ShiftTypeScheme,
  date: z.string().optional(),
  startAt: z.string(),
  finishAt: z.string(),
  restStartAt: z.string().optional(),
  restFinishAt: z.string().optional(),
  contentType: ShiftContentTypeScheme.optional(),
  contentDetails: z.string().optional(),
});
export type Shift = zod.infer<typeof ShiftScheme>;

export const ShiftFormScheme = ShiftScheme;

export type ShiftFormType = zod.infer<typeof ShiftFormScheme>;

export const ShiftCheckScheme = ShiftFormScheme.extend({
  organizationMemberId: z.number(),
});

export const ShiftClientScheme = ShiftScheme.extend({
  id: z.number(),
  organizationMemberId: z.number(),
  date: z.string(),
  isHoliday: z.boolean(),
});
export type ShiftClient = zod.infer<typeof ShiftClientScheme>;

export const baseShifts: Shift[] = [
  {
    shiftType: "fulltime",
    startAt: "09:00",
    finishAt: "18:00",
    restStartAt: "12:00",
    restFinishAt: "13:00",
    isHoliday: false,
    contentType: "office",
  },
  {
    shiftType: "hourly_work",
    startAt: "09:00",
    finishAt: "12:00",
    restStartAt: "12:00",
    restFinishAt: "13:00",
    isHoliday: false,
    contentType: "office",
  },
  {
    shiftType: "half_holiday",
    startAt: "09:00",
    finishAt: "18:00",
    isHoliday: true,
    contentType: "office",
  },
  {
    shiftType: "holiday",
    startAt: "09:00",
    finishAt: "18:00",
    isHoliday: true,
    contentType: "other",
  },
  {
    shiftType: "paid_holiday",
    startAt: undefined,
    finishAt: undefined,
    isHoliday: true,
    contentType: "other",
  },
] as const;
