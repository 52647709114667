<template>
  <div>
    <InputForm
      v-model="modelValue"
      :is-valid="isValid"
      :form-id="formId"
      form-type="email"
      autuocomplete="email"
      class="w-full"
    />
  </div>
</template>

<script setup lang="ts">
import { InputForm } from "/@/vue/components/Atom";

defineProps<{
  formId?: string;
  isValid?: boolean;
}>();

const modelValue = defineModel<string>();
</script>

<style lang="scss" scoped></style>
