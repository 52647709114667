<template>
  <div id="articles-root">
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { useUser } from "/@/vue/composables";
import { UserHeader } from "/@/vue/components/Layouts";

const { user } = useUser();
</script>

<style lang="scss" scoped></style>
