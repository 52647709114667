<template>
  <div>
    <form @submit.prevent="signup">
      <template v-if="!confirming">
        <div v-if="joiningOrganizationId" class="mb-4">
          <Tag value="参加する組織" class="me-4" />
          <span>{{ joiningOrganization?.name }}</span>
        </div>
        <SelectObject
          v-else-if="targetOrganizations.length > 0"
          v-model="selectedOrganizationId"
          option-label="name"
          option-value="id"
          :options="targetOrganizations"
          class="mb-8"
        />
        <template v-if="selectedOrganizationId || joiningOrganizationId">
          <UserForm
            v-model:last-name="signUpForm.lastName"
            v-model:first-name="signUpForm.firstName"
            v-model:last-name-kana="signUpForm.lastNameKana"
            v-model:first-name-kana="signUpForm.firstNameKana"
            v-model:birthday="signUpForm.birthday"
            v-model:email="signUpForm.email"
            v-model:email-check="emailCheck"
            v-model:phone-number="signUpForm.phoneNumber"
            v-model:postalCode="signUpForm.postalCode"
            v-model:address-level1="signUpForm.addressLevel1"
            v-model:address-level2="signUpForm.addressLevel2"
            v-model:address-line1="signUpForm.addressLine1"
            v-model:address-line2="signUpForm.addressLine2"
            :errors="errors"
          />

          <template v-if="withPassword">
            <FormItemPassword
              v-model:value="signUpForm.password"
              :errors="errors"
              label="パスワード(半角英数字6文字以上)"
              form-id="password"
              autocomplete="new-password"
              class="mb-8"
              style="max-width: 300px"
            />
            <FormItemPassword
              v-model:value="signUpForm.passwordConfirmation"
              :errors="errors"
              label="パスワード(確認)"
              form-id="passwordConfirmation"
              form-type="password"
              autocomplete="new-password"
              style="max-width: 300px"
            />
          </template>

          <div class="mt-8 flex justify-end">
            <BasicButton class="me-1" variant="secondary" @click="backto">
              キャンセル
            </BasicButton>
            <BasicButton class="ms-1" @click="confirm"> 確認 </BasicButton>
          </div>
        </template>
      </template>
      <template v-else>
        <UserShow :user="signUpForm" />

        <div class="mt-8 flex justify-end">
          <BasicButton class="me-1" outlined @click="confirming = false">
            修正
          </BasicButton>
          <BasicButton
            class="ms-1"
            button-type="submit"
            :disabled="loading"
            @click="signup"
          >
            アカウントの作成
          </BasicButton>
        </div>
      </template>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { scrollToTop } from "/@/modules/ui";
import { getAddress } from "/@/modules/address";
import { gPhoneNumber, gKana, gPassword, gPostcode } from "/@/modules/groomer";
import {
  useZodScheme,
  useRouterUtil,
  useUser,
  useOrganization,
} from "/@/vue/composables";
import { BasicButton, SelectObject } from "/@/vue/components/Atom";
import { FormItemPassword } from "/@/vue/components/Molecules";
import {
  Form as UserForm,
  Show as UserShow,
} from "/@/vue/components/Organisms/Users";
import { User, SignUpScheme, SignUpForm, OrganizationClient } from "/@/types";
import { errorHandle } from "/@/modules/error";
import Tag from "primevue/tag";

//

const props = defineProps<{
  loading?: boolean;
  isSubstitute?: boolean;
  hideForm?: boolean;
  withOrganization?: boolean;
  needOrganization?: boolean;
  withPassword?: boolean;
  isOpenSite?: boolean;
}>();

interface Emits {
  (e: "signup", user: User): void;
}

const emit = defineEmits<Emits>();

const { backto, currentRouteQuery } = useRouterUtil();

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

// organization

const { getOrganizations } = useOrganization();
const { data: candidateOrganizations } = getOrganizations();

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const managedOrganizations = computed(() => {
  if (!organizations.value) return [];
  return organizations.value.filter((o) => o.isManager);
});

const targetOrganizations = computed<OrganizationClient[]>(() => {
  if (currentUser.value) {
    return managedOrganizations.value;
  } else {
    return candidateOrganizations.value || [];
  }
});

const selectedOrganizationId = ref<number>();

const joiningOrganizationId = computed(() => {
  const idString = currentRouteQuery.value?.organizationId;

  if (!idString) return;
  return parseInt(idString);
});

const joiningOrganization = computed(() => {
  if (!joiningOrganizationId.value && !selectedOrganizationId.value) return;

  const oid = joiningOrganizationId.value || selectedOrganizationId.value;

  return targetOrganizations.value.find((o) => o.id === oid);
});

// form

onMounted(() => {
  /*
  signUpForm.lastName = "試験";
  signUpForm.lastNameKana = "シケン";
  signUpForm.firstName = "ついか";
  signUpForm.firstNameKana = "ツイカ";
  signUpForm.birthday = "1990-01-17";
  signUpForm.email = "s1200191@gmail.com";
  signUpForm.phoneNumber = "09012345678";
  signUpForm.postalCode = "9200966";
  signUpForm.addressLevel1 = "石川県";
  signUpForm.addressLevel2 = "金沢市";
  signUpForm.addressLine1 = "片町1-1建物〇〇102";
  emailCheck.value = "s1200191@gmail.com";
  */

  signUpForm.password = "password";
  signUpForm.passwordConfirmation = "password";
});

const { useFormAndErrors } = useZodScheme();
const {
  form: signUpForm,
  errors,
  startValidation,
} = useFormAndErrors<SignUpForm>(SignUpScheme, {
  birthday: "1990-01-01",
});

const emailCheck = ref("");

startValidation.value = true;

watch(
  () => signUpForm.postalCode,
  async (p, old) => {
    if (p !== old) {
      const res = await getAddress(p);

      if (res) {
        const { pref, city, town } = res;
        signUpForm.addressLevel1 = pref;
        signUpForm.addressLevel2 = city;
        signUpForm.addressLine1 = town;
      }
    }
  }
);

watch(signUpForm, (f) => {
  // form を watch する場合は、まとめて変更した時の処理を書けるが、
  // 無駄な比較処理が増えるのと過去の値へのアクセスができない問題がある。
  // そのため個別のプロパティでの変更があった時のような処理が書けない

  if (f.firstName && f.firstName.length > 50) {
    signUpForm.firstName = f.firstName.slice(0, 50);
  }

  if (f.lastName && f.lastName.length > 50) {
    signUpForm.lastName = f.lastName.slice(0, 50);
  }

  if (f.firstNameKana) {
    const kana = gKana(f.firstNameKana);
    signUpForm.firstNameKana = kana.slice(0, 50);
  }

  if (f.lastNameKana) {
    const kana = gKana(f.lastNameKana);
    signUpForm.lastNameKana = kana.slice(0, 50);
  }

  if (f.phoneNumber) {
    signUpForm.phoneNumber = gPhoneNumber(f.phoneNumber);
  }

  if (f.postalCode) {
    signUpForm.postalCode = gPostcode(f.postalCode);
  }

  if (f.password) {
    signUpForm.password = gPassword(f.password);
  }
});

// confirm

const confirming = ref(false);

function confirm() {
  try {
    if (signUpForm.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    SignUpScheme.parse(signUpForm);
    scrollToTop();
    confirming.value = true;
  } catch (e) {
    alert("入力内容に問題があります。赤枠の項目を確認して下さい。");
    errorHandle(e);
  }
}

const { addNewMember } = useOrganization();

// submit

async function signup() {
  try {
    console.log("signup", joiningOrganization.value);

    const jo = joiningOrganization.value;
    if (!jo?.id) return;

    if (signUpForm.email !== emailCheck.value) {
      throw new Error("メールアドレスが一致しません。");
    }

    const user = SignUpScheme.parse(signUpForm);

    if (await addNewMember(jo.id, user)) {
      alert("登録しました。");
      backto({ confirm: false });
    } else {
      alert("登録に失敗しました。");
    }
  } catch (e) {
    alert("入力内容に問題があります。赤枠の項目を確認して下さい。");
    errorHandle(e);
  }
}
</script>

<style scoped></style>
