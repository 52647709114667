import useSWRV from "swrv";
import { Ref, ComputedRef } from "vue";
import {
  OrganizationMemberClient,
  OrganizationMemberForm,
} from "/@/types/organizationMember.d";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import {
  DateMap,
  Organization,
  OrganizationClient,
  SignUpForm,
} from "/@/types";

export function useOrganization() {
  async function updateOrganization(id: number, organization: Organization) {
    try {
      await axios.patch(`/api/v1/organizations/${id}`, {
        organization: organization,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function addNewMember(id: number, SingUpForm: SignUpForm) {
    try {
      await axios.post(`/api/v1/organizations/${id}/add_new_member`, {
        organization: SingUpForm,
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  function getOrganizations() {
    return useSWRV<OrganizationClient[]>("/api/v1/organizations", fetcher);
  }

  return {
    updateOrganization,
    addNewMember,
    getOrganizations,
  };
}

export function useOrganizationMember() {
  function getOrganizationMembers(
    organizationId: Ref<number | undefined> | ComputedRef<number | undefined>,
    query?: Record<string, string>,
    selectedDate?: DateMap
  ) {
    // only same organization users when user is not admin
    function getKeyFn(
      organizationId: Ref<number | undefined> | ComputedRef<number | undefined>
    ) {
      if (!organizationId.value) {
        return null;
      }

      let uri = `/api/v1/organizations/${organizationId.value}/organization_members?`;

      if (selectedDate) {
        uri += `year=${selectedDate.year}&month=${selectedDate.month}`;
      }

      if (query) {
        return `${uri}&${new URLSearchParams(query)}`;
      } else {
        return uri;
      }
    }

    return useSWRV<OrganizationMemberClient[]>(
      () => getKeyFn(organizationId),
      fetcher
    );
  }

  async function updateOrganizationMember(om: OrganizationMemberForm) {
    try {
      await axios.patch(`/api/v1/organization_members/${om.id}`, {
        organizationMember: om,
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  async function updateLaborInfo(
    omId: number,
    selectedDate: DateMap,
    field: string,
    value: number
  ) {
    try {
      // YYYY-MM
      const targetMonth = `${selectedDate.year}-${selectedDate.month
        .toString()
        .padStart(2, "0")}`;

      await axios.patch(`/api/v1/labor_informations/${targetMonth}`, {
        laborInformation: {
          organizationMemberId: omId,
          [field]: value,
        },
      });
      return true;
    } catch (error) {
      errorHandle(error);
      return false;
    }
  }

  return {
    getOrganizationMembers,
    updateOrganizationMember,
    updateLaborInfo,
  };
}
