<template>
  <div>
    <FormItemWrapper :form-id="formId" :errors="errors" :label="label">
      <DateForm
        v-model="modelValue"
        :form-id="formId"
        :invalid="!!errors[formId]"
        :start-date="startDate"
        :disabled="disabled"
      />
    </FormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { DateForm } from "/@/vue/components/Atom";
import { FormItemWrapper } from "/@/vue/components/Molecules";
import { ZodFormattedErrors } from "/@/types";
import { DateTime } from "luxon";

defineProps<{
  formId: string;
  label?: string;
  startDate?: DateTime;
  disabled?: boolean;
  errors: ZodFormattedErrors;
}>();

const modelValue = defineModel<string | undefined>();
</script>

<style scoped></style>
