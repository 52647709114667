<template>
  <div class="flex justify-center items-center content-center h-full">
    <div class="min-w-96 max-w-full w-8/12">
      <PasswordChangeForm @submit="handleSubmit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useUser, useRouterUtil } from "/@/vue/composables";
import { PasswordChangeForm } from "/@/vue/components/Organisms/Users";
import { PasswordChange } from "/@/types";

const { goto, currentRouteQuery } = useRouterUtil();

const resetPasswordToken = computed(() => {
  return currentRouteQuery.value.reset_password_token;
});

const { getCurrentUser, resetPassword } = useUser();
const { data: user, mutate: getCurrentUserMutate } = getCurrentUser();

async function handleSubmit(form: PasswordChange) {
  if (await resetPassword(form, { token: resetPasswordToken.value })) {
    alert("パスワードを変更しました。");
    await getCurrentUserMutate();

    goto({ name: "UsersShow" });
  } else {
    alert("パスワードの変更に失敗しました。");
  }
}
</script>

<style scoped></style>
