import Edit from "./Edit.vue";
import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "UsersWorkLogsIndex",
    component: Index,
    meta: {
      title: "業務記録",
    },
  },
  {
    path: ":id/edit",
    name: "UsersWorkLogsEdit",
    component: Edit,
    meta: {
      title: "編集",
      tree: [{ title: "業務記録", name: "UsersWorkLogsIndex" }],
    },
  },
];
