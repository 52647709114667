<template>
  <div>
    <div class="mb-8">
      <FormLabel label="欠勤理由" />
      <Select
        v-model="absenceType"
        :options="absenceTypes"
        :option-label="absenceTypesLabel"
        class="w-100"
      />
    </div>

    <div v-if="absenceType === 'illness'" class="mb-8">
      <FormLabel label="体温計の写真" />
      <FormItemPhoto v-model="photo" :errors="errors" form-id="photo" />
    </div>

    <div class="mb-8">
      <FormLabel label="説明" />
      <FormItemTextarea
        v-model="explanation"
        :errors="errors"
        form-id="explanation"
        form-type="text"
        :rows="3"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Label as FormLabel } from "/@/vue/components/Atom";
import {
  FormItemPhoto,
  FormItemTextarea,
} from "/@/vue/components/Molecules/Form";
import {
  ZodFormattedErrors,
  AbsenceForm,
  absenceTypes,
  absenceTypesLabel,
} from "/@/types";
import Select from "primevue/select";

const absenceType = defineModel<AbsenceForm["absenceType"]>("absenceType");
const bodyTemperature =
  defineModel<AbsenceForm["bodyTemperature"]>("bodyTemperature");
const photo = defineModel<AbsenceForm["photo"]>("photo");
const explanation = defineModel<AbsenceForm["explanation"]>("explanation");

defineProps<{
  errors: ZodFormattedErrors;
}>();
</script>

<style scoped></style>
