import { computed } from "vue";
import { RouteLocationRaw, useRouter, useRoute } from "vue-router";

export function useRouterUtil() {
  const router = useRouter();
  const route = useRoute();

  const currentRoute = computed(() => {
    return router.currentRoute.value;
  });

  const currentRouteParams = computed(() => {
    return router.currentRoute.value.params;
  });

  const currentRouteName = computed(() => {
    return router.currentRoute.value.name?.toString();
  });

  const currentRouteQuery = computed(() => {
    return router.currentRoute.value.query;
  });

  const id = computed(() => {
    const queryId = currentRouteParams.value.id;

    if (!queryId || Array.isArray(queryId)) {
      return undefined;
    }

    return parseInt(queryId);
  });

  type FRouteMeta = {
    title?: string;
    dontNeedLogin?: boolean;
    tree?: { title: string; name: string }[];
  };

  const currentRouteMeta = computed<FRouteMeta>(() => {
    return router.currentRoute.value.meta;
  });

  const currentRouteNeedLogin = computed(() => {
    return !currentRouteMeta.value.dontNeedLogin;
  });

  // goto

  function goto(to: RouteLocationRaw) {
    router.push(to);
  }

  function backto(options = { confirm: true }) {
    const { confirm } = options;

    if (confirm) {
      if (
        !window.confirm(
          "前のページに戻る場合、この操作により現在入力した内容が失なわれます。前のページに戻る場合は「OK」を押して下さい。"
        )
      ) {
        return;
      }
    }

    if (window.history.length > 1) {
      router.go(-1);
    } else {
      router.push("/");
    }
  }

  function reload() {
    router.go(0);
  }

  return {
    goto,
    backto,
    reload,

    currentRoute,
    currentRouteParams,
    currentRouteQuery,
    currentRouteName,
    currentRouteMeta,
    currentRouteNeedLogin,

    id,
  };
}
