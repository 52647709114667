<template>
  <Textarea
    :class="formClass"
    :value="modelValue"
    :placeholder="placeholder"
    :rows="rows?.toString() || 4"
    @input="update"
    @compositionstart="compositionStart"
    @compositionend="compositionEnd"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import Textarea from "primevue/textarea";

const props = defineProps<{
  isValid?: boolean;
  formId?: string;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
}>();

const modelValue = defineModel<string>();

const formClass = computed(() => {
  return {
    "form-control": true,
    "is-invalid": !props.isValid,
  };
});

// composition

const compositioning = ref(false);

function compositionStart() {
  compositioning.value = true;
}

function compositionEnd(e: CompositionEvent) {
  compositioning.value = false;
  // @ts-ignore
  modelValue.value = e.target?.value;
}

// update

function update(e: Event) {
  if (compositioning.value) return;

  if (e.target instanceof HTMLTextAreaElement) {
    modelValue.value = e.target.value;
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}
</script>

<style lang="scss" scoped>
textarea {
  height: 100px;
}
</style>
