<template>
  <div>
    <div class="mb-8 flex align-center justify-end" style="height: 50px">
      <div v-if="article?.trashed">
        <Chip
          label="ゴミ箱の記事"
          class="ms-3"
          style="background-color: #ef4444; color: white"
        />
      </div>
    </div>

    <div v-if="article">
      <ArticleForm
        v-model:label="form.label"
        v-model:title="form.title"
        v-model:published-at="form.publishedAt"
        v-model:content="form.content"
        v-model:new-attached-files="form.newAttachedFiles"
        :attached-files="article.attachedFiles"
        :errors="errors"
      />

      <div class="flex justify-end w-full">
        <BasicButton
          label="キャンセル"
          variant="secondary"
          class="me-5"
          outlined
          @click="backto"
        />
        <BasicButton label="更新" button-type="submit" @click="submit" />
      </div>
    </div>
    <i v-else class="pi pi-spin pi-spinner"></i>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { scrollToTop } from "/@/modules/ui";
import {
  useZodScheme,
  useArticle,
  useRouterUtil,
  useUser,
} from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Form as ArticleForm } from "/@/vue/components/Organisms/Articles";
import {
  ArticleCheckScheme,
  ArticleForm as ArticleFormType,
  ArticleClient,
} from "/@/types/article.d";
import Chip from "primevue/chip";

const { getCurrentUser } = useUser();
const { data: user } = getCurrentUser();

const { getArticle, updateArticle } = useArticle();

const { currentRouteParams, backto, goto } = useRouterUtil();
const id = computed(() => Number(currentRouteParams.value.id));

const { data: article } = getArticle(id, { admin: true });

watch(article, (n) => {
  if (!form.id && n) {
    resetForm(n);
  }
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<ArticleFormType>(
  ArticleCheckScheme,
  article.value
);

watch(form, (f) => {
  if (f.title && f.title.length > 50) {
    form.title = f.title.slice(0, 50);
  }
});

function resetForm(article?: ArticleClient) {
  form.id = article?.id || undefined;
  form.title = article?.title || undefined;
  form.content = article?.content || undefined;
  form.publishedAt = article?.publishedAt || undefined;
  form.label = article?.label || "announce";
  form.newAttachedFiles =
    article?.attachedFiles.map((file) => file.signedId) || [];
}

onMounted(() => {
  startValidation.value = true;
  if (article.value) {
    resetForm(article.value);
  }
  scrollToTop();
});

function changeShouldUndefinedParams(form: ArticleFormType) {}

async function submit() {
  try {
    startValidation.value = true;

    changeShouldUndefinedParams(form);

    const args = ArticleCheckScheme.parse(form);

    if (await updateArticle(id.value, args)) {
      alert("更新しました");
      goto({ name: "UsersArticlesIndex" });
    } else {
      alert(
        "更新に失敗しました。添付されている画像が大きすぎます。画像が添付されていないにも関わらずこの表示が出る場合は、お手数ですが開発会社までご連絡をお願いします。"
      );
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
