<template>
  <div>
    <div class="mb-8">
      <FormLabel label="タイトル（必須） ※50文字以内" />
      <FormItemText
        v-model="title"
        :errors="errors"
        form-id="title"
        form-type="text"
      />
    </div>

    <div class="mb-8">
      <FormLabel label="資料種別選択" />
      <Dropdown
        v-model="label"
        :options="articleLabels"
        :option-label="articleLabelTranslater"
        class="w-100"
      />
    </div>

    <div v-if="label !== 'announce'" class="mb-8">
      <FormLabel label="開催日" />
      <div class="d-flex">
        <FormItemDate
          v-model="heldAt"
          :is-valid="!heldAt"
          :errors="errors"
          form-id="heldAt"
          form-type="date"
          class="me-2"
          style="width: 210px; min-width: 210px"
        />
        <FormItemTime
          v-model="heldAt"
          :errors="errors"
          form-id="heldAt"
          :minutes-increment="5"
          :style="
            isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: '120px',
                }
          "
        />
      </div>
    </div>

    <div v-if="false" class="mb-8">
      <FormLabel :label="`本文（必須） ※1MB以内`" />
      <FormItemEditor v-model="content" form-id="content" :errors="errors" />
    </div>

    <div v-if="formattachedFiles.length" class="mb-8">
      <FormLabel label="添付書類" />
      <div
        v-for="(file, idx) in formattachedFiles"
        :key="file.signedId"
        class="border p-2 mb-2 rounded-lg"
      >
        <a :href="file.url" target="_blank">
          <div class="flex flex-wrap items-center">
            <div class="me-4 flex items-center">
              <span class="me-2">添付{{ idx + 1 }} {{ file.filename }}</span>
              <i class="pi pi-external-link"></i>

              <BasicButton
                icon="pi pi-trash"
                need-icon
                variant="danger"
                text
                class="ms-2"
                @click="removeAttachedPdf(file)"
              />
            </div>
          </div>
          <img
            v-if="isImageContentType(file.contentType)"
            style="width: 150px; height: auto"
            :src="file.url"
          />
        </a>
      </div>
    </div>

    <FormItemAttachFile
      v-if="newAttachedFiles && formattachedFiles.length < 4"
      :model-value="newAttachedFiles"
      :file-limit="4 - formattachedFiles.length"
      :current-count="formattachedFiles.length"
      :errors="errors"
      form-id="newAttachedFiles"
      class="mb-8"
      :is-invalid="!!errors?.newAttachedFiles"
      @update:model-value="
        $emit('update:newAttachedFiles', [
          ...newAttachedFiles.filter((p) => !p.startsWith('data')),
          ...$event,
        ])
      "
    />

    <div class="mb-8">
      <FormLabel label="公開日時 ※未設定の場合は即座に公開されます" />
      <div class="flex">
        <FormItemDate
          v-model="publishedAt"
          :errors="errors"
          form-id="publishedAt"
          form-type="date"
          class="me-2"
          style="width: 210px; min-width: 210px"
        />
        <FormItemTime
          v-model="publishedAt"
          :env="publishedAtEnv"
          :errors="errors"
          form-id="publishedAt"
          label=""
          :minutes-increment="10"
          :style="
            isMobile
              ? {
                  width: '100%',
                }
              : {
                  width: '120px',
                }
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { fromISO, luxonNow } from "/@/modules/luxon";
import { labelTranslater as articleLabelTranslater } from "/@/modules/article";
import { isImageContentType } from "/@/modules/file";
import { useMqUtils } from "/@/vue/composables";
import { Label as FormLabel, BasicButton } from "/@/vue/components/Atom";
import {
  FormItemText,
  FormItemEditor,
  FormItemTime,
  FormItemDate,
  FormItemAttachFile,
} from "/@/vue/components/Molecules/Form";
import {
  ZodFormattedErrors,
  FileInfo,
  ArticleLabel,
  articleLabels,
} from "/@/types";
//import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";

const title = defineModel<string | undefined>("title");
const label = defineModel<ArticleLabel | undefined>("label");
const content = defineModel<string | undefined>("content");
const heldAt = defineModel<string | undefined>("heldAt");
const publishedAt = defineModel<string | undefined>("publishedAt");
const newAttachedFiles = defineModel<string[]>("newAttachedFiles", {
  required: true,
});

const props = defineProps<{
  attachedFiles: FileInfo[];
  errors: ZodFormattedErrors;
}>();

const { isMobile } = useMqUtils();

/*
const addedNewsAreas = computed(() => {
  return newsAreas.concat("(指定なし)");
});
*/

const heldAtEnv = computed(() => {
  if (!heldAt.value) return;

  return fromISO(heldAt.value)?.toObject();
});

const publishedAtEnv = computed(() => {
  if (!publishedAt.value) return;

  return fromISO(publishedAt.value)?.toObject();
});

const now = computed(() => {
  return luxonNow().startOf("day");
});

function removeAttachedPdf(file: { url: string; signedId: string }) {
  const rest = newAttachedFiles.value.filter((p) => p !== file.signedId);
  newAttachedFiles.value = rest;
}

const formattachedFiles = computed(() => {
  if (!newAttachedFiles.value) return [];

  return props.attachedFiles.filter((file) => {
    return newAttachedFiles.value.some(
      (signedId) => file.signedId === signedId
    );
  });
});
</script>

<style scoped>
img {
  max-width: 300px;
  max-height: 400px;
}
</style>
