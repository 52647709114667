import useSWRV from "swrv";
import { computed, Ref } from "vue";
import {
  UserForm,
  UserClient,
  UserLabel,
  PasswordChange,
  DateMap,
  HomeInfo,
} from "/@/types";
import { useStore } from "/@/vue/store";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useUser() {
  const store = useStore();

  const user = computed<UserClient | undefined>(() => {
    return store.state.user.user;
  });

  async function getLoginUser() {
    // return stored user
    await store.dispatch("user/GET_USER");
  }

  function getCurrentUser(query?: Record<string, string | boolean>) {
    let uri = `/api/v1/current_user`;

    if (query) {
      uri = `${uri}?${new URLSearchParams(query)}`;
    }

    return useSWRV<UserClient>(uri, fetcher);
  }

  function getUsers() {
    // only same organization users when user is not admin
    return useSWRV<UserClient[]>("/api/v1/users", fetcher);
  }

  function updateUser(user: UserForm) {
    try {
      axios.patch("/api/v1/users", {
        user,
      });
    } catch (e) {
      errorHandle(e);
    }
  }

  // password

  async function sendResetPasswordEmail(email: string) {
    try {
      await axios.post("/api/v1/users/send_reset_password_email", {
        user: {
          email,
        },
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function resetPassword(
    form: PasswordChange,
    { token }: { token: string }
  ) {
    try {
      await axios.patch(`/api/v1/users/reset_password?token=${token}`, {
        user: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  // label

  function hasLabel(user: UserClient, search: Partial<UserLabel>) {
    return user.userLabels.some(
      (label) =>
        !search.labelType ||
        (label.labelType === search.labelType && !search.dependancyType) ||
        (label.dependancyType === search.dependancyType &&
          !search.dependancyId) ||
        label.dependancyId === search.dependancyId
    );
  }

  // apis

  function getHome() {
    return useSWRV<HomeInfo>("/api/v1/users/home", fetcher);
  }

  function getCalendar(selectedDate: Ref<DateMap>) {
    function keys() {
      console.log("getCalendar", selectedDate.value);

      if (!selectedDate.value.year || !selectedDate.value.month) {
        return `/api/v1/users/calendar`;
      }

      return `/api/v1/users/calendar?year=${selectedDate.value.year}&month=${selectedDate.value.month}`;
    }

    return useSWRV(() => keys(), fetcher);
  }

  return {
    user,
    getLoginUser,
    getCurrentUser,
    getUsers,
    updateUser,

    sendResetPasswordEmail,
    resetPassword,

    hasLabel,

    // api
    getHome,
    getCalendar,
  };
}
