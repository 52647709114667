<template>
  <div>
    <SelectObject
      v-if="organizations && organizations.length"
      v-model="selectedOrganizationId"
      option-label="name"
      option-value="id"
      :options="organizations"
      style="min-width: 150px"
      class="mb-8"
    />

    <div class="py-3">
      <AttendanceDetailsCard
        v-if="currentAttendance"
        :attendances="[currentAttendance]"
      />

      <div class="mb-8">
        <div v-if="shifts.length">
          <ShiftDetailsCard v-for="s in shifts" :shift="s" />
        </div>
      </div>
    </div>

    <Card v-if="selectedOrganization" class="mb-8">
      <!-- attendance form -->
      <template #content>
        <GetLocation
          v-model:lat="form.finishLat"
          v-model:lng="form.finishLng"
        />
      </template>
    </Card>

    <Card class="mb-8">
      <template #content>
        <AttendanceForm
          v-if="currentAttendance"
          v-model:evaluation-type="form.evaluationType"
          v-model:report="form.report"
          v-model:submit-correction-request="submitCorrectionRequest"
          v-model:start-at="form.correctionStartAt"
          v-model:finish-at="form.correctionFinishAt"
          :current-attendance="currentAttendance"
          :errors="errors"
        />
      </template>
    </Card>

    <div class="flex justify-end w-full">
      <div class="me-2">
        <BasicButton
          variant="danger"
          icon="pi pi-times"
          label="キャンセル"
          @click="backto()"
        />
      </div>
      <div class="">
        <BasicButton
          button-type="submit"
          icon="pi pi-play"
          label="業務終了"
          :disabled="disabledFinish"
          @click="finish"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import {
  useRouterUtil,
  useUser,
  useAttendance,
  useZodScheme,
} from "/@/vue/composables";
import { BasicButton, SelectObject } from "/@/vue/components/Atom";
import {
  DetailsCard as AttendanceDetailsCard,
  Form as AttendanceForm,
} from "/@/vue/components/Organisms/Attendances";
import { DetailsCard as ShiftDetailsCard } from "/@/vue/components/Organisms/Shifts";
import {
  AttendanceCheckScheme,
  AttendanceClient,
  AttendanceForm as AttendanceFormType,
} from "/@/types";
import Card from "primevue/card";
import { luxonNow } from "/@/modules/luxon";
import { GetLocation } from "/@/vue/components/Molecules";

const { backto, goto } = useRouterUtil();

const { getCurrentUser, getHome } = useUser();
const { data: currentUser } = getCurrentUser();

const { data: homeInfo } = getHome();

const currentAttendance = computed(() => {
  if (!homeInfo.value) return;
  return homeInfo.value.currentAttendance;
});

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const selectedOrganizationId = ref<number>();
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

// form

const today = luxonNow();

const submitCorrectionRequest = ref(false);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<AttendanceFormType>(
  AttendanceCheckScheme,
  currentAttendance.value
);

watch(form, (f) => {
  if (f.report && f.report.length > 1000) {
    form.report = f.report.slice(0, 1000);
  }
});

function resetForm(attendance?: AttendanceClient) {
  form.id = attendance?.id || undefined;
  form.evaluationType = attendance?.evaluationType || "excellent";
  form.report = attendance?.report || undefined;
  form.correctionStartAt = today.set({ hour: 9, minute: 0 }).toISO();
  form.correctionFinishAt = today.set({ hour: 18, minute: 0 }).toISO();
}

onMounted(() => {
  startValidation.value = true;
  if (currentAttendance.value) {
    resetForm(currentAttendance.value);
  }
});

watch(currentAttendance, (catt) => {
  if (catt) {
    console.log("watch catt resetForm");
    resetForm(catt);
  }
});

// attendance

const { finish: finishAttendance } = useAttendance();

const disabledFinish = computed(() => {
  return !currentAttendance.value || !form.finishLat || !form.finishLat;
});

async function finish() {
  if (!currentAttendance.value) return;

  if (!submitCorrectionRequest.value) {
    form.correctionStartAt = undefined;
    form.correctionFinishAt = undefined;
  }

  if (await finishAttendance(form)) {
    goto({ name: "UsersIndex" });
  } else {
    alert("業務終了に失敗しました");
  }
}

// shift
const shifts = computed(() => {
  const organization = selectedOrganization.value;
  const homeInf = homeInfo.value;
  if (!organization || !homeInf) return [];

  return homeInf.shifts.filter(
    (s) => s.organizationMemberId === organization.organizationMemberId
  );
});
</script>

<style scoped></style>
