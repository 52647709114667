<template>
  <Inputtext
    :id="formId"
    :name="formName"
    :value="modelValue"
    :class="formClass"
    :type="formType"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :max="max"
    @input="update"
    @compositionstart="compositionStart"
    @compositionend="compositionEnd"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Inputtext from "primevue/inputtext";

interface Props {
  isValid?: boolean;
  formId?: string;
  placeholder?: string;
  autocomplete?: string;
  formType?: string;
  formName?: string;
  icon?: string;
  disabled?: boolean;
  readonly?: boolean;
  step?: number;
  textEnd?: boolean;
  withoutBorder?: boolean;
  whenChange?: boolean;
  max?: number;
  min?: number;
  pattern?: string;
}

const modelValue = defineModel<string | undefined>();

const props = defineProps<Props>();

const formClass = computed(() => {
  return {
    "form-control": true,
    "!border-red-500": !props.isValid,
  };
});

const compositioning = ref(false);

function update(e: Event) {
  if (compositioning.value) return;

  if (e.target instanceof HTMLInputElement) {
    modelValue.value = e.target.value;
  } else {
    throw new Error("予期せぬエラーが発生しました");
  }
}

function compositionStart() {
  compositioning.value = true;
}

function compositionEnd(e: CompositionEvent) {
  compositioning.value = false;
  // @ts-ignore
  if (e.target instanceof HTMLInputElement) {
    modelValue.value = e.target.value;
  }
}
</script>

<style lang="scss" scoped></style>
