import Edit from "./Edit.vue";

export default [
  {
    path: "edit",
    name: "UsersPasswordEdit",
    component: Edit,
    meta: {
      dontNeedLogin: true,
      title: "パスワード変更",
      tree: [{ title: "パスワード変更" }],
    },
  },
];
