<template>
  <div>
    <FormItemWrapper :form-id="formId" :errors="errors" :label="label">
      <TakePhoto v-model:photo="modelValue" :is-valid="!errors[formId]" />
    </FormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { FormItemWrapper, TakePhoto } from "/@/vue/components/Molecules";
import { PhotoDataObject, ZodFormattedErrors } from "/@/types";

defineProps<{
  formId: string;
  label?: string;
  errors: ZodFormattedErrors;
}>();

const modelValue = defineModel<PhotoDataObject | undefined>();
</script>

<style scoped></style>
