<template>
  <div>
    <Card v-if="!flatten">
      <template #content>
        <div class="mb-4">シフト詳細</div>
        <div class="flex items-center mb-2">
          <Tag class="me-4" :value="shiftTypeLabel(shift.shiftType)"></Tag>

          <div v-if="!isHoliday">
            {{ basicFormatter(shift.startAt, "onlyTime") }}〜{{
              basicFormatter(shift.finishAt, "onlyTime")
            }}
          </div>
        </div>
        <div
          v-if="
            shift.shiftType !== 'holiday' && shift.shiftType !== 'paid_holiday'
          "
          class="flex"
        >
          <Tag
            v-if="shift.contentType"
            :value="shiftContentTypeLabel(shift.contentType)"
            class="me-4"
          />

          <div v-if="shift.contentDetails">
            {{ shift.contentDetails }}
          </div>
        </div>
      </template>
    </Card>
    <div
      v-else
      class="border rounded-lg bg-white dark:bg-gray-800 dark:border-gray-700"
      style="padding: var(--p-card-body-padding)"
    >
      <div class="mb-4">シフト詳細</div>
      <div class="flex items-center mb-2">
        <Tag class="me-4" :value="shiftTypeLabel(shift.shiftType)"></Tag>

        <div v-if="!isHoliday">
          {{ basicFormatter(shift.startAt, "onlyTime") }}〜{{
            basicFormatter(shift.finishAt, "onlyTime")
          }}
        </div>
      </div>
      <div
        v-if="
          shift.shiftType !== 'holiday' && shift.shiftType !== 'paid_holiday'
        "
        class="flex"
      >
        <Tag
          v-if="shift.contentType"
          :value="shiftContentTypeLabel(shift.contentType)"
          class="me-4"
        />

        <div v-if="shift.contentDetails">
          {{ shift.contentDetails }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import {
  Shift,
  ShiftClient,
  shiftContentTypeLabel,
  shiftTypeLabel,
} from "/@/types";
import Card from "primevue/card";
import Tag from "primevue/tag";

const props = defineProps<{
  shift: ShiftClient | Shift;
  flatten?: boolean;
}>();

const isHoliday = computed(() => {
  return (
    props.shift.shiftType === "holiday" ||
    props.shift.shiftType === "paid_holiday"
  );
});
</script>

<style scoped></style>
