import useSWRV from "swrv";
import { axios, deepUndefineKeyToNull, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import {
  MemberApplicationClient,
  MemberApplicationType,
} from "/@/types/memberApplication";

export function useMemberApplications() {
  async function bulkCreateMemberApplications(
    memberId: number,
    dates: string[],
    applicationType: MemberApplicationType
  ) {
    try {
      await axios.post(
        `/api/v1/organization_members/${memberId}/member_applications/bulk_create`,
        {
          memberApplication: {
            applicationType: applicationType,
            dates,
          },
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function processMemberApplication(
    id: number,
    processType: "approve" | "reject"
  ) {
    try {
      await axios.post(`/api/v1/member_applications/${id}/${processType}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    bulkCreateMemberApplications,
    processMemberApplication,
  };
}
