<template>
  <div>
    <DataTable
      v-model:selection="selectedItems"
      striped-rows
      paginator
      lazy
      :rows="perPage"
      :total-records="totalPages * perPage"
      :value="articles"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      selection-mode="multiple"
      scrollable
      scroll-height="60vh"
      @page="onPage"
      @row-click="$emit('selectArticle', $event.data.id)"
    >
      <template #header>
        <div class="flex items-center">
          <div
            v-if="selectedItems.length"
            class="table-action-menu-button-wrapper"
          >
            <MenuButton :menu-items="menuItems" />
          </div>
          <TableHeader
            v-model="perPage"
            :total-pages="totalPages"
            :current-page="currentPage"
            @update:model-value="currentPage = 1"
          />
        </div>
      </template>
      <Column
        v-if="!isMobile"
        selection-mode="multiple"
        header-style="width: 2rem"
      >
      </Column>
      <Column sortable field="title" header="タイトル">
        <template #body="{ data }">
          <span>{{ data.title }}</span>
        </template>
      </Column>
      <Column
        sortable
        field="publishedAt"
        header="公開日時"
        :header-style="
          isMobile
            ? { width: '170px', 'min-width': '170px' }
            : { width: '170px', 'min-width': '170px' }
        "
      >
        <template #body="{ data }">
          <span>{{ basicFormatter(data.publishedAt, "slashStyle") }}</span>
        </template>
      </Column>
      <Column :style="{ width: '60px', 'min-width': '60px' }">
        <template #body="{ data }">
          <div class="d-flex">
            <BasicButton
              variant="danger"
              icon="pi pi-trash"
              need-icon
              text
              button-type="submit"
              @click="$emit('trashArticle', data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import { ArticleClient } from "/@/types";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import type { MenuItem } from "primevue/menuitem";

const currentPage = defineModel<number>("currentPage", { required: true });
const perPage = defineModel<number>("perPage", { required: true });

const props = defineProps<{
  articles: ArticleClient[];
  totalPages: number;
}>();

const emit = defineEmits<{
  (e: "selectArticle", id: number): void;
  (e: "trashArticle", id: number | number[]): void;
}>();

const { isMobile } = useMqUtils();

const selectedItems = ref<ArticleClient[]>([]);

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "ゴミ箱に移動する",
      icon: "pi pi-trash",
      command: () => {
        emit(
          "trashArticle",
          selectedItems.value.map((n) => n.id)
        );
        selectedItems.value = [];
      },
    },
  ];
});

function onPage(event: { page: number }) {
  currentPage.value = event.page + 1;
}
</script>

<style lang="scss">
// in Organizations/Tabel.vue
</style>
