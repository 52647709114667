import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { DateTime } from "luxon";

// datemap

const DateMapScheme = z.object({
  year: z.number(),
  month: z.number(),
  day: z.string().optional(),
});

export type DateMap = zod.infer<typeof DateMapScheme>;

const DateMapFormScheme = DateMapScheme.partial();

export type DateMapForm = zod.infer<typeof DateMapFormScheme>;
