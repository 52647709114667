import Absence from "./Absence.vue";
import Edit from "./Edit.vue";
import Index from "./Index.vue";
import New from "./New.vue";

export default [
  {
    path: "",
    name: "UsersOrganizationsIndex",
    component: Index,
    meta: {
      title: "組織情報・管理",
    },
  },
  {
    path: ":id/edit",
    name: "UsersOrganizationsEdit",
    component: Edit,
    meta: {
      title: "組織編集",
      tree: [{ title: "組織情報・管理", name: "UsersOrganizationsIndex" }],
    },
  },
];
