<template>
  <div>
    <form @submit.prevent="login">
      <FormItemEmail
        v-model="loginForm.email"
        :errors="errors"
        label="メール"
        form-id="email"
        class="mb-8 w-full"
      />
      <FormItemPassword
        v-model="loginForm.password"
        :errors="errors"
        label="パスワード"
        form-id="password"
      />
      <div class="mb-8"></div>
      <slot></slot>
      <div v-if="!loading" class="flex items-center justify-between mt-3">
        <BasicButton button-type="submit" @click="login">
          ログイン
        </BasicButton>
        <router-link
          :to="{ name: 'PasswordReset' }"
          style="color: rgba(var(--bs-link-color-rgb))"
        >
          パスワードを忘れた方はこちら
        </router-link>
      </div>
      <i v-else class="pi pi-spin pi-spinner"></i>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useZodScheme } from "/@/vue/composables/zod";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItemEmail, FormItemPassword } from "/@/vue/components/Molecules";
import { User, LoginForm, LoginScheme } from "/@/types";

interface Props {
  loading?: boolean;
}

interface Emits {
  (e: "login", user: User): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

// form

const { useFormAndErrors } = useZodScheme();
const {
  form: loginForm,
  errors,
  startValidation,
} = useFormAndErrors<LoginForm>(LoginScheme);

function login() {
  try {
    console.log("login");
    startValidation.value = true;
    const user = LoginScheme.parse(loginForm);
    emit("login", user);
  } catch (e) {
    console.log("");
  }
}
</script>

<style scoped></style>
