import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";
import { Ref } from "vue";

export function useWorkLog() {
  // jpHoliday

  function getWorkLogs() {
    return useSWRV("/api/v1/work_logs", fetcher);
  }

  function getWorkLogKey(id: Ref<number | undefined>) {
    return id.value ? `/api/v1/work_logs/${id.value}` : null;
  }

  function getWorkLog(id: Ref<number | undefined>) {
    return useSWRV(getWorkLogKey(id), fetcher);
  }

  return {
    getWorkLogs,
    getWorkLog,
  };
}
