import Index from "./Index.vue";

export default [
  {
    path: "edit",
    name: "UsersCalendarIndex",
    component: Index,
    meta: {
      title: "カレンダー",
    },
  },
];
