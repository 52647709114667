<template>
  <div>
    <DataTable
      v-model:selection="selectedItems"
      striped-rows
      paginator
      lazy
      :rows="perPage"
      :total-records="totalPages * perPage"
      :value="workLogs"
      :table-style="isMobile ? 'width: 100%' : 'min-width: 50rem'"
      scrollable
      scroll-height="60vh"
      @page="onPage"
      @row-click="$emit('select', $event.data.id)"
    >
      <template #header>
        <div class="flex items-center">
          <div
            v-if="selectedItems.length"
            class="table-action-menu-button-wrapper"
          >
            <MenuButton :menu-items="menuItems" />
          </div>
          <TableHeader
            v-model="perPage"
            :total-pages="totalPages"
            :current-page="currentPage"
            @update:model-value="currentPage = 1"
          />
        </div>
      </template>
      <Column sortable field="targetDate" header="日にち">
        <template #body="{ data }">
          <span>{{ basicFormatter(data.targetDate, "slashStyle") }}</span>
        </template>
      </Column>
      <Column :style="{ width: '60px', 'min-width': '60px' }">
        <template #body="{ data }">
          <div class="d-flex">
            <BasicButton
              variant="success"
              icon="pi pi-pencil"
              need-icon
              text
              button-type="submit"
              @click="$emit('edit', data.id)"
            />
          </div>
        </template>
      </Column>
      <Column :style="{ width: '60px', 'min-width': '60px' }">
        <template #body="{ data }">
          <div class="d-flex">
            <BasicButton
              variant="danger"
              icon="pi pi-trash"
              need-icon
              text
              button-type="submit"
              @click="$emit('trash', data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { basicFormatter } from "/@/modules/luxon";
import { useMqUtils, useTableHeader } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules";
import { Header as TableHeader } from "/@/vue/components/Molecules/TableUtils";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import type { MenuItem } from "primevue/menuitem";
import { WorkLogClient } from "/@/types/workLog";

const currentPage = defineModel<number>("currentPage", { required: true });
const perPage = defineModel<number>("perPage", { required: true });

const props = defineProps<{
  workLogs: WorkLogClient[];
  totalPages: number;
}>();

const emit = defineEmits<{
  (e: "select", id: number): void;
  (e: "trash", id: number | number[]): void;
  (e: "edit", id: number): void;
}>();

const { isMobile } = useMqUtils();

const selectedItems = ref<WorkLogClient[]>([]);

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "ゴミ箱に移動する",
      icon: "pi pi-trash",
      command: () => {
        emit(
          "trash",
          selectedItems.value.map((n) => n.id)
        );
        selectedItems.value = [];
      },
    },
  ];
});

function onPage(event: { page: number }) {
  currentPage.value = event.page + 1;
}
</script>

<style lang="scss">
// in Organizations/Tabel.vue
</style>
