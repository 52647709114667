<template>
  <div>
    <Menu :id="randomId" ref="menu" :model="menuItems" :popup="true" />
  </div>
  <Button
    type="button"
    icon="pi pi-ellipsis-v"
    aria-haspopup="true"
    :aria-controls="randomId"
    :label="label"
    @click="toggle"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import type { MenuItem } from "primevue/menuitem";

defineProps<{
  menuItems: MenuItem[];
  label?: string;
}>();

const randomId = "menu-" + Math.random().toString(36).slice(-8);

const menu = ref();

function toggle(event: any) {
  event.stopPropagation();
  menu.value.toggle(event);
}
</script>
