import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { PhoneNumber } from "libphonenumber-js";

export const OrganizationScheme = z.object({
  id: z.number().optional(),
  name: z.string(),
  phoneNumber: z.string(),
  postalCode: z.string().trim().min(7).max(7).or(z.literal("")),
  addressLevel1: z.string().trim().min(0).max(30).or(z.literal("")),
  addressLevel2: z.string().trim().min(0).max(30).or(z.literal("")),
  addressLine1: z.string().trim().min(0).max(255).or(z.literal("")),
  addressLine2: z.string().trim().min(0).max(255).or(z.literal("")).optional(),
});
export type Organization = zod.infer<typeof OrganizationScheme>;

export const OrganizationCheckScheme = OrganizationScheme;

export const OrganizationFormScheme = OrganizationScheme.partial();
export type OrganizationForm = zod.infer<typeof OrganizationFormScheme>;

export const OrganizationClientScheme = OrganizationScheme.extend({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  organizationMemberId: z.number(),
  isManager: z.boolean(),
  baseStartAt: z.string().optional(),
  baseFinishAt: z.string().optional(),
});
export type OrganizationClient = zod.infer<typeof OrganizationClientScheme>;
