import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { FileInfoScheme } from "/@/types/file.d";
import { BrowserPhotoScheme } from "/@/types/browserPhoto.d";

export const articleLabels = [
  "announce", // お知らせ
  "open_lecture", // 公開講座
  "training", // 研修会
] as const;
export const ArticleLabelScheme = z.enum(articleLabels);
export type ArticleLabel = zod.infer<typeof ArticleLabelScheme>;

export const ArticleScheme = z.object({
  id: z.number().optional(),
  label: ArticleLabelScheme,
  title: z.string().trim().min(0).max(50),
  publishedAt: z.string().optional(),
  content: z.string().trim().min(0).max(16777215).optional(),
});

export type Article = zod.infer<typeof ArticleScheme>;

export const ArticleCheckScheme = ArticleScheme;

export const ArticleFormScheme = ArticleScheme.partial({
  id: true,
  title: true,
  publishedAt: true,
  content: true,
});
export type ArticleForm = zod.infer<typeof ArticleFormScheme>;

export const ArticleClientScheme = ArticleScheme.extend({
  id: z.number(),
  publishedAt: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  attachedFiles: FileInfoScheme.array(),
  trashed: z.boolean(),
  unread: z.boolean(),
  nowPinned: z.boolean(),
});
export type ArticleClient = zod.infer<typeof ArticleClientScheme>;
