<template>
  <div>
    <div class="flex justify-end items-center mb-8">
      <MenuButton label="各種申請" :menu-items="menuItems" />
    </div>

    <Card>
      <template #content>
        <CalendarCole
          v-if="jpHolidays"
          :events="events"
          v-model:selected-date="selectedDate"
          :jp-holidays="jpHolidays"
        />
      </template>
    </Card>

    <SimpleModal v-model:visible="holidayAppModalVisible" title="休日申請">
      <div class="">
        <div class="flex flex-col align-center justify-center mb-8">
          <SelectObject
            v-if="organizations && organizations.length"
            v-model="selectedOrganizationId"
            option-label="name"
            option-value="id"
            :options="organizations"
            style="min-width: 150px"
            class="mb-8"
          />

          <DateForm v-model="form.date" multiple inline :min-date="today" />
        </div>
        <div class="flex align-center justify-end">
          <BasicButton
            variant="secondary"
            label="キャンセル"
            class="mr-4"
            @click="closeHolidayAppModal"
          />
          <BasicButton
            variant="primary"
            label="申請"
            @click="submitHolidayApp"
          />
        </div>
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { luxonNow } from "/@/modules/luxon";
import { createCalendarEvent } from "/@/modules/calendar";
import {
  useCalendar,
  useUser,
  useMemberApplications,
} from "/@/vue/composables";
import { Core as CalendarCole } from "/@/vue/components/Organisms/Calendar";
import {
  DateForm,
  Label as FormLabel,
  SelectObject,
} from "/@/vue/components/Atom";
import { MenuButton } from "/@/vue/components/Molecules/Button";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import {
  DateMap,
  MemberApplicationStatus,
  MemberApplicationType,
  shiftTypeLabel,
} from "/@/types";
import Card from "primevue/card";
import type { MenuItem } from "primevue/menuitem";
import BasicButton from "/@/vue/components/Atom/Buttons/BasicButton.vue";
import { memberTypeLabel } from "/@/types";

const { getJpHolidays } = useCalendar();
const { data: jpHolidays } = getJpHolidays();

const today = luxonNow();

const selectedDate = ref<DateMap>({
  year: today.year,
  month: today.month,
});

// user

const { getCurrentUser, getCalendar } = useUser();
const { data: currentUser } = getCurrentUser({});
const { data: calendarInfo, mutate: getCalendarMutate } =
  getCalendar(selectedDate);

// organizations

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const selectedOrganizationId = ref<number>();
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

// event

const primaryColor = "#f97316";
const dangerColor = "#f87171";
const successColor = "#34d399";
const warningColor = "#f59e0b";

const events = computed(() => {
  if (!calendarInfo.value) return [];

  const shiftEvnts = calendarInfo.value.shifts.map((shift) => {
    return createCalendarEvent({
      id: `shift-${shift.id}`,
      title: shiftTypeLabel(shift.shiftType),
      start: shift.date,
      allDay: true,
      color: shift.isHoliday ? dangerColor : primaryColor,
    });
  });

  const applicationEvents = calendarInfo.value.memberApplications.map((app) => {
    return createCalendarEvent({
      id: `application-${app.id}`,
      title:
        memberTypeLabel(app.applicationType) +
        memberApplicationStatusToCalendarStatus(app.memberApplicationStatus),
      start: app.date,
      allDay: true,
      color: app.applicationType === "paid" ? dangerColor : successColor,
    });
  });

  return [...shiftEvnts, ...applicationEvents];
});

// modal

const selectedMemberApplicationType = ref<MemberApplicationType>("paid");

const menuItems = computed<MenuItem[]>(() => {
  return [
    {
      label: "休日申請",
      icon: "pi pi-calendar",
      command: () => {
        selectedMemberApplicationType.value = "holiday";
        openHolidayAppModal();
      },
    },
    {
      label: "有休申請",
      icon: "pi pi-calendar",
      command: () => {
        selectedMemberApplicationType.value = "paid";
        openHolidayAppModal();
      },
    },
  ];
});

//

function memberApplicationStatusToCalendarStatus(
  status: MemberApplicationStatus
) {
  switch (status) {
    case "pending":
      return "(承認待ち)";
    case "approved":
      return "";
    case "rejected":
      return "(却下)";
  }
}

// modal

const holidayAppModalVisible = ref(false);

function openHolidayAppModal() {
  holidayAppModalVisible.value = true;
}

function closeHolidayAppModal() {
  holidayAppModalVisible.value = false;
}

const form = reactive({
  date: [],
});

const { bulkCreateMemberApplications } = useMemberApplications();

async function submitHolidayApp() {
  const organization = selectedOrganization.value;

  if (!organization) return;

  if (
    await bulkCreateMemberApplications(
      organization.organizationMemberId,
      form.date,
      selectedMemberApplicationType.value
    )
  ) {
    form.date = [];
    getCalendarMutate();
    closeHolidayAppModal();
  } else {
    console.error("Failed to submit holiday application");
  }
}
</script>

<style scoped></style>
