<template>
  <div>
    <FormItemWrapper :form-id="formId" :errors="errors" :label="label">
      <AttachFileForm
        v-model="modelValue"
        :form-id="formId"
        :file-limit="fileLimit"
        :file-min="fileMin"
        class="w-full"
      />
    </FormItemWrapper>
  </div>
</template>

<script setup lang="ts">
import { AttachFileForm } from "/@/vue/components/Atom";
import { FormItemWrapper } from "/@/vue/components/Molecules";
import { ZodFormattedErrors } from "/@/types";
import { DateTime } from "luxon";

defineProps<{
  formId: string;
  label?: string;
  fileLimit?: number;
  fileMin?: number;
  errors: ZodFormattedErrors;
}>();

const modelValue = defineModel<string[]>();
</script>

<style scoped></style>
