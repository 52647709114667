import zod from "zod";
import { z } from "/@/modules/zodUtils";

export const FileInfoScheme = z.object({
  signedId: z.string(),
  filename: z.string(),
  url: z.string(),
  contentType: z.string(),
});
export type FileInfo = zod.infer<typeof FileInfoScheme>;
