<template>
  <Datepicker
    :model-value="jsDate"
    :min-Date="minDate?.toJSDate()"
    :maxDate="maxDate?.toJSDate()"
    :manual-input="false"
    :selection-mode="multiple ? 'multiple' : 'single'"
    :inline="inline"
    date-format="yy/mm/dd"
    :invalid="invalid"
    :show-icon="showIcon"
    :show-button-bar="showButtonBar"
    :disabled="disabled"
    @update:model-value="update"
  >
    <template #date="{ date }">
      <p
        :class="{
          'text-red-500':
            getWeekdayNumber(new Date(date.year, date.month, date.day)) === 0,
          'text-blue-500':
            getWeekdayNumber(new Date(date.year, date.month, date.day)) === 6,
        }"
      >
        {{ date.day }}
      </p>
    </template>
  </Datepicker>
  <!--
  <BasicDatepicker
      ref="datepicker"
      :form-id="formId"
      :input="isoDate"
      :auto-apply="autoApply"
      :min-date="minDate?.toJSDate()"
      :max-date="maxDate?.toJSDate()"
      :start-date="startDate?.toJSDate()"
      :input-class-name="`${isPc ? '' : 'mobile-ui'}`"
      :state="isValid ? undefined : false"
      :multi-dates="multiDates"
      :range="range"
      :disabled-dates="disabledDates"
      :allowed-dates="allowedDates"
      :markers="markers"
      :inline="inline"
      :slash-style="slashStyle"
      :without-border="withoutBorder"
      :teleport-center="teleportCenter"
      :disabled="disabled"
      @update:input="update"
      @update-month-year="handleUpdateMonthYear"
    />
  -->
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import { computed, ref } from "vue";
import { fromDate, fromISO, luxonNow } from "/@/modules/luxon";
// import { BasicDatepicker } from "/@/vue/components/Atom";
import Datepicker from "primevue/datepicker";

const modelValue = defineModel<string | string[]>();

const props = withDefaults(
  defineProps<{
    isPc?: boolean;
    minDate?: DateTime;
    maxDate?: DateTime;
    startDate?: DateTime;
    invalid?: boolean;
    multiple?: boolean;
    range?: boolean;
    autoApply?: boolean;
    inline?: boolean;
    disabledDates?: string[];
    allowedDates?: string[];
    showIcon?: boolean;
    showButtonBar?: boolean;
    disabled?: boolean;
    formId?: string;
    formName?: string;
    baseTime?: { hour: number; minute: number };
    slashStyle?: boolean;
    withoutBorder?: boolean;
    teleportCenter?: boolean;
  }>(),
  {
    today: () => luxonNow(),
    minDate: undefined,
    maxDate: undefined,
    startDate: undefined,
    autoApply: true,
    disabledDates: undefined,
    allowedDates: undefined,
    markers: undefined,
    showIcon: true,
    showButtonBar: true,
    baseTime: { hour: 9, minute: 0 },
    formId: "date",
  }
);

const emit = defineEmits(["updateMonthYear"]);

const jsDate = computed<Date | Date[] | undefined>(() => {
  if (!modelValue.value) {
    return undefined;
  }

  if (modelValue.value instanceof Array) {
    const dates = modelValue.value
      .map((v) => fromISO(v)?.toJSDate())
      .filter((item): item is Date => item !== undefined);
    return dates;
  } else {
    return fromISO(modelValue.value)?.toJSDate() || undefined;
  }
});

function update(v: Date | Date[] | PickerTimeObject | undefined) {
  if (!v) {
    modelValue.value = undefined;
    return;
  }

  if (v instanceof Date) {
    let t = fromDate(v);

    if (props.baseTime) {
      t = t.set({ hour: props.baseTime.hour, minute: props.baseTime.minute });
    }

    console.log("t", t.toISO() ?? undefined);

    modelValue.value = t.toISO() ?? undefined;
  } else if (v instanceof Array) {
    modelValue.value = v.map((d) => {
      let t = fromDate(d);

      if (props.baseTime) {
        t = t.set({ hour: props.baseTime.hour, minute: props.baseTime.minute });
      }

      const iso = t.toISO();

      if (!iso) {
        throw new Error("Invalid date");
      }
      return iso;
    });
  }
}

// component methods

const datepicker = ref();

function openMenu() {
  datepicker.value?.openMenu();
}

defineExpose({
  openMenu,
});

function getWeekdayNumber(date: Date) {
  return date.getDay();
}
</script>

<style lang="scss" scoped></style>
